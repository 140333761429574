import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { verifyAddRoomData } from "../../../../../utils/validation";
import ErrorMessage from "../../../../../components/errorMessage/ErrorMessage";
import Modal from "../../../../../components/modal";
import updateHotelRoomApi from "../../../../../services/hotel/hotelRoom/updateHotelRoomApi";
import deleteHotelRoomApi from "../../../../../services/hotel/hotelRoom/deleteHotelRoomApi";

const initialServices = {
  wifi: false,
  tv: false,
  heating: false,
  air_condition: false,
  hot_water: false,
};

const EditRoomModal = ({
  hotelId,
  isOpen,
  setIsOpen,
  defaultData,
  roomDropdownOptions,
  setMutation,
}) => {
  const [roomType, setRoomType] = useState();
  const [count, setCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [services, setServices] = useState(initialServices);
  const [description, setDescription] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setRoomType(defaultData?.roomType._id || undefined);
      setCount(defaultData?.count || 0);
      setPrice(defaultData?.price || 0);
      setServices(defaultData?.services || initialServices);
      setDescription(defaultData?.description || "");
      return;
    }

    setCount(0);
    setPrice(0);
    setServices(initialServices);
    setDescription("");

    setErrorMsg("");
  }, [isOpen, defaultData]);

  useEffect(() => {
    if (!roomType || !roomDropdownOptions.length) {
      return;
    }
  }, [roomType, roomDropdownOptions]);

  useEffect(() => {
    setErrorMsg("");
  }, [roomType, count, price, services, description]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isVerified, message } = verifyAddRoomData({
      roomType,
      count,
      price,
      description,
    });

    if (!isVerified) {
      setErrorMsg(message);
      return;
    }

    setIsProcessing(true);

    const formattedServices = [];

    Object.keys(services).forEach((key) => {
      if (services[key]) {
        formattedServices.push(key);
      }
    });

    const roomData = {
      price,
      count,
      roomTypeId: roomType,
      description,
      services: formattedServices,
    };

    try {
      await updateHotelRoomApi({
        hotelId,
        roomId: defaultData?._id,
        data: roomData,
      });

      setMutation(true);
      setIsOpen(false);

      toast.success("Successfully updated");
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }

    setIsProcessing(false);
  };

  const deleteRoom = async () => {
    try {
      await deleteHotelRoomApi(hotelId, defaultData?._id);

      setMutation(true);
      setIsOpen(false);
      toast.success("Room successfully deleted");
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="w-full max-w-[644px]"
    >
      <h1 className="text-[24px] font-medium">Edit Room</h1>

      <form className="mt-[24px]" onSubmit={handleSubmit}>
        <ErrorMessage errorMsg={errorMsg} className="mb-[16px]" />

        <div>
          <label htmlFor="room-type" className="label">
            Room Type
          </label>

          <select
            className="input mt-[8px] bg-transparent"
            value={roomType || 0}
            onChange={(e) => setRoomType(e.target.value)}
          >
            <option value={0} hidden>
              Select a room
            </option>

            {roomDropdownOptions.map((item) => (
              <option key={item._id} value={item._id}>
                {item.type}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-[16px]">
          <label htmlFor="" className="label">
            Number of Room
          </label>

          <input
            type="text"
            className="input mt-[8px]"
            placeholder="Enter the number of room"
            value={count || ""}
            onChange={(e) => {
              const value = e.target.value;

              if ((!isNaN(value) && +value) || !value) {
                setCount(value);
              }
            }}
          />
        </div>

        <div className="mt-[16px]">
          <label htmlFor="" className="label">
            Price per Night
          </label>

          <input
            type="text"
            className="input mt-[8px]"
            placeholder="Enter the price per night"
            value={price || ""}
            onChange={(e) => {
              const value = e.target.value;

              if ((!isNaN(value) && +value) || !value) {
                setPrice(value);
              }
            }}
          />
        </div>

        <div className="mt-[16px] select-none">
          <label htmlFor="services" className="label">
            Services(inc.room price)
          </label>

          <div className="mt-[8px] grid grid-cols-2 cursor-pointer">
            <div className="flex items-center space-x-1">
              <input
                type="checkbox"
                checked={services.wifi}
                onChange={() =>
                  setServices({ ...services, wifi: !services.wifi })
                }
              />
              <span
                className="text-[14px] font-medium text-neutral-900"
                onClick={() =>
                  setServices({ ...services, wifi: !services.wifi })
                }
              >
                Wifi
              </span>
            </div>

            <div className="mt-[8px] flex items-center space-x-1 cursor-pointer">
              <input
                type="checkbox"
                checked={services.air_condition}
                onChange={() =>
                  setServices({
                    ...services,
                    air_condition: !services.air_condition,
                  })
                }
              />
              <span
                className="text-[14px] font-medium text-neutral-900"
                onClick={() => {
                  setServices({
                    ...services,
                    air_condition: !services.air_condition,
                  });
                }}
              >
                Air Condition
              </span>
            </div>

            <div className="mt-[8px] flex items-center space-x-1 cursor-pointer first-letter">
              <input
                type="checkbox"
                checked={services.tv}
                onChange={() =>
                  setServices({
                    ...services,
                    tv: !services.tv,
                  })
                }
              />
              <span
                className="text-[14px] font-medium text-neutral-900"
                onClick={() =>
                  setServices({
                    ...services,
                    tv: !services.tv,
                  })
                }
              >
                TV
              </span>
            </div>

            <div className="mt-[8px] flex items-center space-x-1 cursor-pointer">
              <input
                type="checkbox"
                checked={services.hot_water}
                onChange={() =>
                  setServices({
                    ...services,
                    hot_water: !services.hot_water,
                  })
                }
              />
              <span
                className="text-[14px] font-medium text-neutral-900"
                onClick={() =>
                  setServices({
                    ...services,
                    hot_water: !services.hot_water,
                  })
                }
              >
                Hot Water
              </span>
            </div>

            <div className="mt-[8px] flex items-center space-x-1 cursor-pointer">
              <input
                type="checkbox"
                checked={services.heating}
                onChange={() =>
                  setServices({
                    ...services,
                    heating: !services.heating,
                  })
                }
              />
              <span
                className="text-[14px] font-medium text-neutral-900"
                onClick={() =>
                  setServices({
                    ...services,
                    heating: !services.heating,
                  })
                }
              >
                Heating
              </span>
            </div>
          </div>
        </div>

        <div className="mt-[16px]">
          <label htmlFor="description" className="label">
            Description
          </label>
          <textarea
            cols="20"
            rows="3"
            placeholder="Room description"
            className="input mt-[8px]"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="mt-[24px] flex justify-between gap-5">
          <button
            className="cancel-btn mt-5 text-neutral-800 bg-neutral-200 hover:bg-neutral-300"
            type="button"
            onClick={deleteRoom}
          >
            Remove Room
          </button>

          <div className="flex gap-5">
            <button
              className="cancel-btn mt-5 text-neutral-800 bg-neutral-200 hover:bg-neutral-300"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </button>

            <button
              className="primary-btn mt-5"
              type="submit"
              disabled={errorMsg || isProcessing}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditRoomModal;
