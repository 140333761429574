import { useEffect, useState } from "react";
import BodyHeader from "../../components/bodyHeader";
import CreateRoomModal from "../../features/room/CreateRoomModal";
import UpdateRoomModal from "../../features/room/UpdateRoomModal";
import RoomTableList from "../../features/room/RoomTableList";
import usePagination from "../../hooks/usePagination";
import getRoomsApi from "../../services/room/getRoomsApi";

const Room = () => {
  const roomData = usePagination({ fetchMethod: getRoomsApi });
  const searchRoomData = usePagination({
    fetchMethod: getRoomsApi,
    isInitialAutoFetch: false,
  });

  const [searchText, setSearchText] = useState("");
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const [isUpdateModalOpened, setIsUpdateModalOpened] = useState(false);

  const activeTab = searchText.length >= 2 ? searchRoomData : roomData;

  useEffect(() => {
    searchRoomData.setSearchValue(searchText);
  }, [searchText]);

  const createMutation = () => {
    roomData.setCreateMutation();

    if (searchText.trim().length >= 2) {
      searchRoomData.setCreateMutation();
    }
  };

  const updateMutation = () => {
    roomData.setUpdateMutation();

    if (searchText.trim().length >= 2) {
      searchRoomData.setUpdateMutation();
    }
  };

  const deleteMutation = () => {
    roomData.setDeleteMutation();

    if (searchText.trim().length >= 2) {
      searchRoomData.setDeleteMutation();
    }
  };

  const openUpdatePackageModal = (data) => {
    setSelectedRoom(data);
    setIsUpdateModalOpened(true);
  };

  return (
    <>
      <BodyHeader
        title="Room"
        setSearchValue={setSearchText}
        buttonText="Create Room"
        handleCreateButton={() => setIsCreateModalOpened(true)}
        searchPlaceholder="Search rooms"
        className="app-layout lg:sticky top-0"
      />

      <RoomTableList
        paginationDetails={activeTab}
        deleteMutation={deleteMutation}
        openUpdatePackageModal={openUpdatePackageModal}
      />

      <CreateRoomModal
        isOpen={isCreateModalOpened}
        setIsOpen={setIsCreateModalOpened}
        setMutation={createMutation}
      />

      <UpdateRoomModal
        id={selectedRoom?._id}
        isOpen={isUpdateModalOpened}
        setIsOpen={setIsUpdateModalOpened}
        setMutation={updateMutation}
        defaultData={selectedRoom}
      />
    </>
  );
};

export default Room;
