import { Link } from "react-router-dom";
import LoginForm from "../../features/auth/LoginForm";

const Login = () => {
  return (
    <div className="h-[100vh] grid grid-cols-1 lg:grid-cols-2">
      <div className="hidden lg:block bg-gray-200"></div>

      <div className="px-[16px] py-[60px]">
        <div className="max-w-[456px] mx-auto">
          <p className="text-[12px] text-right font-normal tracking-[0.02em] text-neutral-500">
            Don't have an account ?{" "}
            <Link to="/sign-up" className="text-primary">
              Sign up
            </Link>
          </p>

          <div className="flex justify-center">
            <div className="mt-[80px] w-full">
              <h1 className="text-[28px] tracking-[0.02em] font-medium text-neutral-900">
                Welcome
              </h1>
              <p className="mt-2 text-[16px] font-medium text-neutral-700">
                Login to continue
              </p>
            </div>
          </div>

          <LoginForm className="w-full mt-[50px]" />
        </div>
      </div>
    </div>
  );
};

export default Login;
