import http from "../../utils/http";

const getHotelApi = async ({ page = 1, limit = 25 }) => {
  const response = await http.get("/v1/hotel", { params: { page, limit } });
  console.log('response', response)
  return response.data;

};

export default getHotelApi;
