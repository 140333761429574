import { useEffect, useState } from "react";
import LocationTableList from "../../features/location/LocationTableList";
import { useNavigate } from "react-router-dom";
import BodyHeader from "../../components/bodyHeader";
import usePagination from "../../hooks/usePagination";
import getLocationsApi from "../../services/location/getLocationsApi";

const Location = () => {
  const navigate = useNavigate();

  const locationData = usePagination({ fetchMethod: getLocationsApi });
  const searchLocationData = usePagination({
    fetchMethod: getLocationsApi,
    isInitialAutoFetch: false,
  });

  const [searchText, setSearchText] = useState("");

  const activeTab = searchText.length >= 2 ? searchLocationData : locationData;

  useEffect(() => {
    searchLocationData.setSearchValue(searchText);
  }, [searchText]);

  const deleteMutation = () => {
    locationData.setDeleteMutation();

    if (searchText.trim().length >= 2) {
      searchLocationData.setDeleteMutation();
    }
  };

  return (
    <>
      <BodyHeader
        title="Location"
        setSearchValue={setSearchText}
        buttonText="Add Location"
        handleCreateButton={() => navigate("/location/add")}
        searchPlaceholder="Search locations"
        className="app-layout lg:sticky top-0"
      />

      <LocationTableList
        paginationDetails={activeTab}
        deleteMutation={deleteMutation}
      />
    </>
  );
};

export default Location;
