import cn from "classnames";

const ErrorMessage = ({ className = "", errorMsg = "" }) => {
  if (!errorMsg.trim()) return null;

  return (
    <p
      className={cn(
        "p-3 text-[14px] rounded-sm font-medium text-red-700 bg-red-100",
        className
      )}
    >
      {errorMsg}
    </p>
  );
};

export default ErrorMessage;
