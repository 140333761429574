import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { Menu, Transition } from "@headlessui/react";
import { MoreHorizontal } from "react-feather";
import toast from "react-hot-toast";
import { verifyLocationData } from "../../utils/validation";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import imagesPlaceholder from "../../assets/location/images-placeholder.png";
import addLocationApi from "../../services/location/addLocationApi";

const AddLocationForm = ({ className = "" }) => {
  const navigate = useNavigate();

  const [image, setImage] = useState();
  const [name, setName] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [description, setDescription] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setErrorMsg("");
  }, [image, name, latitude, longitude, description]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isVerified, message } = verifyLocationData({
      image,
      name,
      latitude,
      longitude,
      description,
    });

    if (!isVerified) {
      setErrorMsg(message);
      return;
    }

    setIsProcessing(true);

    const locationData = new FormData();
    locationData.append("coverImage", image);
    locationData.append("name", name);
    locationData.append("coordinates", latitude);
    locationData.append("coordinates", longitude);
    locationData.append("type", "Point");
    locationData.append("description", description);

    try {
      await addLocationApi(locationData);

      toast.success("Location successfully added");
      navigate("/location");
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }

    setIsProcessing(false);
  };

  return (
    <form className={cn(className)} onSubmit={handleSubmit}>
      <ErrorMessage errorMsg={errorMsg} className="mb-[16px]" />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div>
          <div>
            <label htmlFor="" className="label">
              Location Name
            </label>
            <input
              type="text"
              className="input mt-[8px]"
              placeholder="Enter location name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mt-[16px]">
            <label htmlFor="" className="label">
              Latitude
            </label>
            <input
              type="number"
              className="input mt-[8px]"
              placeholder="Enter latitude"
              value={latitude}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  setLatitude(e.target.value);
                }
              }}
            />
          </div>

          <div className="mt-[16px]">
            <label htmlFor="" className="label">
              Longitude
            </label>
            <input
              type="number"
              className="input mt-[8px]"
              placeholder="Enter longitude"
              value={longitude}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  setLongitude(e.target.value);
                }
              }}
            />
          </div>

          <div className="mt-[15px]">
            <label htmlFor="" className="label">
              Description
            </label>
            <textarea
              className="input mt-[8px]"
              name=""
              id=""
              cols="20"
              rows="3"
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div>
          <label className="label">Upload Image</label>
          <div className="relative max-w-[512px] mt-[8px] p-[32px] text-center border rounded-xl">
            {image ? (
              <>
                <img
                  src={URL.createObjectURL(image)}
                  alt="cover pic"
                  className="w-full aspect-[16/9] object-cover"
                />
                <div className="absolute z-10 top-[50px] right-[50px]">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="p-[4px] rounded-full bg-neutral-200 hover:bg-neutral-400">
                        <MoreHorizontal
                          size={18}
                          className="text-neutral-800"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute w-[180px] right-0 mt-1 py-[8px] rounded-[4px] text-[16px] font-normal overflow-hidden [&>*]:block [&>*]:px-3 [&>*]:py-2  text-neutral-900 bg-white shadow-lg">
                        <label className="block cursor-pointer">
                          Change Image
                          <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={(e) => setImage(e.target.files[0])}
                          />
                        </label>
                        <button onClick={() => setImage(null)}>Delete</button>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-center">
                  <img
                    src={imagesPlaceholder}
                    alt="placeholder"
                    className="w-[120px]"
                  />
                </div>

                <h3 className="mt-3 text-[20px] font-medium text-neutral-800">
                  Choose image of the Location
                </h3>

                <label className="label mt-4 font-medium text-[14px] underline cursor-pointer text-neutral-700">
                  Upload from your device
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </label>
              </>
            )}
          </div>
        </div>
      </div>

      <button
        className="primary-btn mt-[38px]"
        type="submit"
        disabled={isProcessing || errorMsg}
      >
        Add Location{isProcessing && "..."}
      </button>
    </form>
  );
};

export default AddLocationForm;
