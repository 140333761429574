import http from "../../utils/http";

const updateHotelSecondaryImgApi = async (hotelId, data) => {
  const response = await http.patch(
    `/v1/hotel/${hotelId}/related-images`,
    data,
    {
      "Contain-Type": "multipart/form-data",
    }
  );
  return response.data;
};

export default updateHotelSecondaryImgApi;
