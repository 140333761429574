import http from "../../utils/http";

const getReviewsApi = async ({ page = 1, limit = 25 }) => {
  const response = await http.get("/v1/admin/review", {
    params: { page, limit },
  });
  return response.data;
};

export default getReviewsApi;
