import cn from "classnames";
import { useEffect, useState } from "react";

const SingleRoom = ({ room, openEditModal, className = "" }) => {
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    const services = [];

    Object.keys(room.services).forEach((item) => {
      if (room.services[item]) {
        services.push(item.split("_").join(" "));
      }
    });

    setServiceList(services);
  }, [room]);

  return (
    <div className={cn("p-6", className)}>
      <div className="flex items-center justify-between gap-2">
        <h2 className="text-[20px] font-medium capitalize text-neutral-900">
          {room?.roomType?.type}
        </h2>
        <p
          className="text-[14px] tracking-[0.4px] font-medium underline cursor-pointer text-neutral-800"
          onClick={() => openEditModal(room)}
        >
          Edit
        </p>
      </div>

      <div className="mt-[12px] flex items-center justify-between gap-2">
        <p className="text-[16px] font-normal text-neutral-700">
          {room.count} Rooms
        </p>
        <p className="text-[24px] font-medium text-neutral-800">
          {room.price}{" "}
          <span className="text-[16px] font-normal">per night</span>
        </p>
      </div>

      <div className="mt-[32px]">
        <p className="text-[20px] font-medium text-neutral-800">Services</p>
        <div className="mt-[16px] flex flex-wrap gap-4">
          {serviceList.map((item, i) => (
            <p
              key={i}
              className="px-4 text-[14px] rounded-md capitalize text-neutral-900 bg-neutral-200"
            >
              {item}
            </p>
          ))}
        </div>
      </div>

      <div className="mt-[32px]">
        <h2 className="text-[20px] font-medium text-neutral-800">
          Description
        </h2>

        <p className="mt-[16px] text-[14px] font-normal tracking-wider leading-[150%] text-neutral-800">
          {room.description}
        </p>
      </div>
    </div>
  );
};

export default SingleRoom;
