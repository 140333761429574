import { ChevronLeft } from "react-feather";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/verify-forgot-password");
  };

  return (
    <div className="h-[100vh] grid grid-cols-1 lg:grid-cols-2">
      <div className="hidden lg:block bg-gray-200"></div>

      <div className="px-[16px] py-[60px]">
        <div className="max-w-[456px] mx-auto">
          <div className="flex items-center justify-between">
            <Link to="/login" className="flex items-center gap-1">
              <ChevronLeft size={16} className="text-primary" />
              <p className="text-[14px] font-medium text-neutral-600">Back</p>
            </Link>

            <p className="text-[12px] font-normal tracking-[0.02em] text-neutral-500">
              Remember Password ?
              <Link to="/login" className="text-primary">
                Login
              </Link>
            </p>
          </div>

          <div className="flex justify-center">
            <div className="mt-[80px] w-full">
              <h1 className="text-[28px] tracking-[0.02em] font-medium text-neutral-900">
                Forgot Password?
              </h1>
              <p className="mt-2 text-[16px] font-medium text-neutral-700">
                Enter the email address and continue to reset your password.
              </p>
            </div>
          </div>

          <form className="mt-[70px]" onSubmit={handleSubmit}>
            <label htmlFor="email" className="label">
              Email
            </label>
            <input type="text" placeholder="Your email" className="input" />

            <button className="mt-[60px] w-full p-3 primary-btn" type="submit">
              Send Code
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
