import http from "../../utils/http";

const getUsersApi = async ({ page = 1, limit = 2, search = undefined }) => {
  const response = await http.get("/v1/user", {
    params: { page, limit, search },
  });
  return response.data;
};

export default getUsersApi;
