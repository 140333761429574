import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import cn from "classnames";
import toast from "react-hot-toast";
import { MoreHorizontal, Plus } from "react-feather";
import { verifyAddHotelData } from "../../../utils/validation";
import SingleRoom from "./components/SingleRoom";
import RoomImageList from "./components/RoomImageList";
import UploadImage from "../components/UploadImage";
import AddLocationModal from "../components/modal/AddLocationModal";
import AddRoomModal from "./components/modals/AddRoomModal";
import EditRoomModal from "./components/modals/EditRoomModal";
import addHotelApi from "../../../services/hotel/addHotelApi";
import getDropdownPackagesApi from "../../../services/package/getDropdownPackages";

const AddHotelForm = ({ className = "" }) => {
  const navigate = useNavigate();

  const [packages, setPackages] = useState([]);

  const [coverImage, setCoverImage] = useState();
  const [relatedImages, setRelatedImages] = useState([]);
  const [name, setName] = useState("");
  const [location, setLocation] = useState(null);
  const [coordinates, setCoordinates] = useState({
    latitude: "",
    longitude: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isBreakfastProvided, setIsBreakfastProvided] = useState(true);
  const [parking, setParking] = useState("available_free");
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [time, setTime] = useState({ checkIn: "", checkOut: "" });
  const [description, setDescription] = useState("");
  const [rooms, setRooms] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false);

  const [isAddRoomModalOpened, setIsAddRoomModalOpened] = useState(false);
  const [isAddLocationModalOpened, setIsAddLocationModalOpened] =
    useState(false);

  const [isEditRoomModalOpened, setIsEditRoomModalOpened] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);

  //Fetching all packages for the package dropdown
  const fetchPackages = async () => {
    try {
      const packageResData = await getDropdownPackagesApi();
      setPackages(packageResData.data);
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  // Clear selected room while closing edit room modal.
  useEffect(() => {
    if (!isEditRoomModalOpened) {
      setSelectedRoom(null);
    }
  }, [isEditRoomModalOpened]);

  const addRoom = (data) => {
    setRooms([...rooms, data]);
  };

  const editRoom = (roomType, data) => {
    setRooms(
      Array.from(rooms, (item) => {
        if (item.room === roomType) {
          return data;
        }
        return item;
      })
    );
  };

  const deleteRoom = (id) => {
    setRooms(rooms.filter((room) => room.room !== id));
  };

  const openEditModal = (data) => {
    setSelectedRoom(data);
    setIsEditRoomModalOpened(true);
  };

  const addNewSecondaryImage = (image) => {
    setRelatedImages([...relatedImages, image]);
  };

  const removeSecondaryImage = (index) => {
    setRelatedImages(relatedImages.filter((item, i) => i !== index));
  };

  const makeCoverImage = (index) => {
    const oldPrimaryImg = coverImage;
    const primaryImg = relatedImages.find((item, i) => i === index);

    if (!primaryImg) {
      return;
    }

    setCoverImage(primaryImg);

    if (oldPrimaryImg) {
      setRelatedImages(
        Array.from(relatedImages, (image, i) => {
          if (i === index) {
            return oldPrimaryImg;
          }
          return image;
        })
      );
    } else {
      setRelatedImages(relatedImages.filter((item, i) => index !== i));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isVerified, message } = verifyAddHotelData({
      name,
      location,
      coordinates,
      phoneNumber,
      selectedPackage,
      time,
      rooms,
      description,
      coverImage,
    });

    if (!isVerified) {
      toast.error(message);
      return;
    }

    setIsProcessing(true);

    const hotelFormData = new FormData();

    hotelFormData.append("name", name);
    hotelFormData.append("latitude", coordinates.latitude);
    hotelFormData.append("longitude", coordinates.longitude);
    hotelFormData.append("locationId", location);
    hotelFormData.append("phoneNumber", phoneNumber);
    hotelFormData.append("secondaryPhoneNumber", secondaryPhoneNumber);
    hotelFormData.append("email", email);
    hotelFormData.append("breakfast", isBreakfastProvided);
    hotelFormData.append("parking", parking);
    hotelFormData.append(
      "packages",
      JSON.stringify([{ packageId: selectedPackage }])
    );
    hotelFormData.append("checkInTime", time.checkIn);
    hotelFormData.append("checkOutTime", time.checkOut);
    hotelFormData.append("description", description);
    hotelFormData.append("coverImage", coverImage);

    relatedImages.forEach((image) => {
      hotelFormData.append("relatedImages", image);
    });

    const formattedRooms = Array.from(rooms, (room) => {
      const formattedServices = [];

      Object.keys(room.services).forEach((key) => {
        if (room.services[key]) {
          formattedServices.push(key);
        }
      });

      return {
        roomTypeId: room.room,
        count: room.count,
        price: room.price,
        description: room.description,
        services: formattedServices,
      };
    });

    hotelFormData.append("rooms", JSON.stringify(formattedRooms));

    try {
      await addHotelApi(hotelFormData);

      toast.success("New Hotel successfully created.");
      navigate("/hotel");
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }

    setIsProcessing(false);
  };

  return (
    <>
      <form className={cn("className", className)} onSubmit={handleSubmit}>
        <div className={cn("grid grid-cols-1 lg:grid-cols-2 gap-10")}>
          <div>
            <div>
              <label htmlFor="" className="label">
                Hotel Name
              </label>
              <input
                type="text"
                className="input mt-[8px]"
                placeholder="Enter location name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mt-[16px] flex items-center justify-between">
              <label htmlFor="" className="label">
                Location ({coordinates.latitude} - {coordinates.longitude})
              </label>
              <Plus
                className="cursor-pointer text-neutral-600"
                onClick={() => setIsAddLocationModalOpened(true)}
              />
            </div>

            <div className="mt-[16px]">
              <label htmlFor="" className="label">
                Contact Number
              </label>
              <input
                type="text"
                className="input mt-[8px]"
                placeholder="Enter primary number"
                value={phoneNumber}
                onChange={(e) => {
                  const value = e.target.value.trim();

                  if (!isNaN(value) && value.length <= 10) {
                    setPhoneNumber(value);
                  }
                }}
              />

              <input
                type="text"
                className="input mt-[8px]"
                placeholder="Enter secondary number"
                value={secondaryPhoneNumber}
                onChange={(e) => {
                  const value = e.target.value.trim();

                  if (!isNaN(value) && value.length <= 10) {
                    setSecondaryPhoneNumber(value);
                  }
                }}
              />
            </div>

            <div className="mt-[16px]">
              <label htmlFor="" className="label">
                Email
              </label>
              <input
                type="email"
                className="input mt-[8px]"
                placeholder="Enter hotel email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="mt-[16px]">
              <label htmlFor="" className="label">
                Services
              </label>
            </div>

            <div className="mt-[16px]">
              <label htmlFor="" className="label">
                Breakfast
              </label>

              <div className="flex items-center space-x-5 mt-1">
                <div className="flex items-center space-x-1">
                  <input
                    type="radio"
                    value="yes"
                    className="cursor-pointer"
                    checked={isBreakfastProvided}
                    onChange={() => setIsBreakfastProvided(true)}
                  />
                  <span className="text-[14px] font-medium text-neutral-900">
                    Yes
                  </span>
                </div>

                <div className="flex items-center space-x-1">
                  <input
                    type="radio"
                    value="no"
                    className="cursor-pointer"
                    checked={!isBreakfastProvided}
                    onChange={() => setIsBreakfastProvided(false)}
                  />
                  <span className="text-[14px] font-medium text-neutral-900">
                    No
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-[16px]">
              <label htmlFor="" className="label">
                Parking
              </label>
              <div className="flex items-center space-x-5 mt-1">
                <div className="flex items-center space-x-1">
                  <input
                    type="radio"
                    className="cursor-pointer"
                    checked={parking === "available_free"}
                    onChange={() => setParking("available_free")}
                  />
                  <span className="text-[14px] font-medium text-neutral-900">
                    Yes(Free)
                  </span>
                </div>

                <div className="flex items-center space-x-1">
                  <input
                    type="radio"
                    className="cursor-pointer"
                    checked={parking === "available_paid"}
                    onChange={() => setParking("available_paid")}
                  />
                  <span className="text-[14px] font-medium text-neutral-900">
                    Yes(Paid)
                  </span>
                </div>

                <div className="flex items-center space-x-1">
                  <input
                    type="radio"
                    className="cursor-pointer"
                    checked={parking === "not_available"}
                    onChange={() => setParking("not_available")}
                  />
                  <span className="text-[14px] font-medium text-neutral-900">
                    No
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-[16px] flex items-center justify-between">
              <label htmlFor="" className="label">
                Room
              </label>
              <Plus
                className="cursor-pointer text-neutral-600"
                onClick={() => setIsAddRoomModalOpened(true)}
              />
            </div>

            {rooms.length ? (
              <div className="mt-[16px] flex flex-col gap-[32px]">
                {rooms.map((room, i) => (
                  <SingleRoom
                    key={i}
                    room={room}
                    openEditModal={openEditModal}
                    className="rounded-md bg-neutral-100"
                  />
                ))}
              </div>
            ) : null}

            <div className="mt-[16px]">
              <label htmlFor="" className="label">
                Package
              </label>
              <select
                className="input mt-[8px] bg-transparent cursor-pointer"
                value={selectedPackage || 0}
                onChange={(e) => setSelectedPackage(e.target.value)}
              >
                <option value="0" hidden>
                  Select a Package
                </option>

                {packages.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-[16px]">
              <label htmlFor="" className="label">
                Time
              </label>
            </div>

            <div className="mt-[16px] flex gap-5">
              <div className="w-full">
                <label htmlFor="" className="label">
                  Check-in
                </label>
                <input
                  type="time"
                  className="input mt-[8px]"
                  value={time.checkIn}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (value < time.checkOut || !time.checkOut) {
                      setTime({ ...time, checkIn: e.target.value });
                    }
                  }}
                />
              </div>

              <div className="w-full">
                <label htmlFor="" className="label">
                  Check-out
                </label>
                <input
                  type="time"
                  className="input mt-[8px]"
                  value={time.checkOut}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (value > time.checkIn || !time.checkIn) {
                      setTime({ ...time, checkOut: value });
                    }
                  }}
                />
              </div>
            </div>

            <div className="mt-[16px]">
              <label htmlFor="" className="label">
                Description
              </label>
              <textarea
                className="input mt-[8px]"
                cols="20"
                rows="3"
                placeholder="Hotel description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>

          <div>
            <div className="relative max-w-[512px] mt-[8px] p-[32px] text-center border rounded-xl">
              {coverImage || relatedImages.length ? (
                <>
                  {coverImage ? (
                    <div>
                      <img
                        src={URL.createObjectURL(coverImage)}
                        alt="cover pic"
                        className="w-full aspect-[16/9] object-cover"
                      />
                      <div className="absolute top-[50px] right-[50px]">
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="p-[4px] rounded-full bg-neutral-200 hover:bg-neutral-400">
                              <MoreHorizontal
                                size={18}
                                className="text-neutral-800"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute w-[180px] right-0 mt-1 py-[8px] rounded-[4px] text-[16px] font-normal overflow-hidden [&>*]:block [&>*]:px-3 [&>*]:py-2  text-neutral-900 bg-white shadow-lg">
                              <label className="block cursor-pointer">
                                Change Image
                                <input
                                  type="file"
                                  accept="image/*"
                                  className="hidden"
                                  onChange={(e) =>
                                    setCoverImage(e.target.files[0])
                                  }
                                />
                              </label>
                              <button onClick={() => setCoverImage(null)}>
                                Delete
                              </button>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                      <p className="absolute  px-2 top-[50px] left-[50px] rounded-md text-neutral-900 bg-neutral-100">
                        Cover Image
                      </p>
                    </div>
                  ) : null}

                  <RoomImageList
                    imageList={relatedImages}
                    addNewSecondaryImage={addNewSecondaryImage}
                    removeSecondaryImage={removeSecondaryImage}
                    makeCoverImage={makeCoverImage}
                    className="mt-[32px]"
                  />
                </>
              ) : (
                <UploadImage setImage={setCoverImage} />
              )}
            </div>
          </div>
        </div>

        <button
          className="primary-btn mt-[38px]"
          type="submit"
          disabled={isProcessing}
        >
          Add Hotel{isProcessing && "..."}
        </button>
      </form>

      <AddRoomModal
        isOpen={isAddRoomModalOpened}
        setIsOpen={setIsAddRoomModalOpened}
        addRoom={addRoom}
      />

      <EditRoomModal
        isOpen={isEditRoomModalOpened}
        setIsOpen={setIsEditRoomModalOpened}
        defaultData={selectedRoom}
        editRoom={editRoom}
        deleteRoom={deleteRoom}
      />

      <AddLocationModal
        isOpen={isAddLocationModalOpened}
        setIsOpen={setIsAddLocationModalOpened}
        location={location}
        setLocation={setLocation}
        coordinates={coordinates}
        setCoordinates={setCoordinates}
      />
    </>
  );
};

export default AddHotelForm;
