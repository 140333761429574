import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import toast from "react-hot-toast";
import { ChevronLeft } from "react-feather";
import { setLogin } from "../../redux/slices/userSlice";
import verifySignUpOtp from "../../services/auth/verifySignUpOtp";

const VerifySignUpOTP = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (otp.length !== 4) {
      toast.error("Fill all the OTP input filed");
      return;
    }

    setIsProcessing(true);

    try {
      const verifyOtpResData = await verifySignUpOtp({
        email: state.email,
        OTP: otp,
      });

      dispatch(
        setLogin({
          isRememberMe: true,
          userData: verifyOtpResData.data.userData,
          token: verifyOtpResData.data.token,
        })
      );

      navigate("/");
    } catch (e) {
      toast.error(e?.response?.data?.error);
    }

    setIsProcessing(false);
  };

  if (!state.email.trim()) {
    navigate("/sign-up");
  }

  return (
    <div className="h-[100vh] grid grid-cols-1 lg:grid-cols-2">
      <div className="hidden lg:block bg-gray-200"></div>

      <div className="px-[16px] py-[60px]">
        <div className="max-w-[456px] mx-auto">
          <div className="flex items-center justify-between">
            <Link to="/sign-up" className="flex items-center gap-1">
              <ChevronLeft size={16} className="text-primary" />
              <p className="text-[14px] font-medium text-neutral-600">Back</p>
            </Link>

            <p className="text-[12px] font-normal tracking-[0.02em] text-neutral-500">
              Already have account ?{" "}
              <Link to="/login" className="text-primary">
                Login
              </Link>
            </p>
          </div>

          <div className="flex justify-center">
            <div className="mt-[80px] w-full">
              <h1 className="text-[28px] tracking-[0.02em] font-medium text-neutral-900">
                Enter OTP Code
              </h1>
              <p className="mt-2 text-[16px] font-medium text-neutral-700">
                We have send 4 digit OTP Code send to admin's email. Enter the
                code in the message to verify your account.
              </p>
            </div>
          </div>

          <form className="mt-[70px]" onSubmit={handleSubmit}>
            <div className="flex justify-center">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                separator={<span className="ml-[25px]"></span>}
                containerStyle=""
                inputStyle="border !w-[60px] !h-[60px] rounded-md outline-[1px] outline-primary"
              />
            </div>

            <button
              className="mt-[60px] w-full p-3 primary-btn"
              type="submit"
              disabled={isProcessing}
            >
              Verify Account{isProcessing && "..."}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifySignUpOTP;
