import Home from "../../pages";
import Hotel from "../../pages/hotel";
import EditHotel from "../../pages/hotel/EditHotel";
import AddHotel from "../../pages/hotel/AddHotel";
import Inquiry from "../../pages/inquiry";
import InquiryByHotel from "../../pages/inquiry/InquiryByHotel";
import Location from "../../pages/location";
import AddLocation from "../../pages/location/AddLocation";
import EditLocation from "../../pages/location/EditLocation";
import Package from "../../pages/package";
import Review from "../../pages/review";
import Room from "../../pages/room";
import User from "../../pages/user";

const appRoutes = [
  {
    id: "home",
    path: "/",
    component: Home,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "location",
    path: "/location",
    component: Location,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "addLocation",
    path: "/location/add",
    component: AddLocation,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "editLocation",
    path: "/location/edit/:id",
    component: EditLocation,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "hotel",
    path: "/hotel",
    component: Hotel,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "edit-hotel",
    path: "/hotel/edit/:id",
    component: EditHotel,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "addHotel",
    path: "/hotel/add",
    component: AddHotel,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "room",
    path: "/room",
    component: Room,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "package",
    path: "/package",
    component: Package,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "review",
    path: "/review",
    component: Review,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "user",
    path: "/user",
    component: User,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "inquiry",
    path: "/inquiry",
    component: Inquiry,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "hotelInquiry",
    path: "/inquiry/hotel/:hotelId",
    component: InquiryByHotel,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
];

export default appRoutes;
