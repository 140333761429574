import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HotelTableList from "../../features/hotel/hotelTableList";
import usePagination from "../../hooks/usePagination";
import BodyHeader from "../../components/bodyHeader";
import getHotelsApi from "../../services/hotel/getHotelsApi";

const Hotel = () => {
  const navigate = useNavigate();

  const hotelData = usePagination({ fetchMethod: getHotelsApi });
  const searchHotelData = usePagination({
    fetchMethod: getHotelsApi,
    isInitialAutoFetch: false,
  });

  const [searchText, setSearchText] = useState("");

  const activeTab = searchText.length >= 2 ? searchHotelData : hotelData;

  useEffect(() => {
    searchHotelData.setSearchValue(searchText);
  }, [searchText]);

  const deleteMutation = () => {
    hotelData.setDeleteMutation();

    if (searchText.trim().length >= 2) {
      searchHotelData.setDeleteMutation();
    }
  };

  return (
    <>
      <BodyHeader
        title="Hotel"
        setSearchValue={setSearchText}
        buttonText="Add Hotel"
        handleCreateButton={() => navigate("/hotel/add")}
        searchPlaceholder="Search hotels"
        className="app-layout lg:sticky top-0"
      />

      <HotelTableList
        paginationDetails={activeTab}
        deleteMutation={deleteMutation}
      />
    </>
  );
};

export default Hotel;
