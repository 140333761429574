import ReactPaginate from "react-paginate";
import cn from "classnames";
import { ChevronLeft, ChevronRight } from "react-feather";

const Pagination = (props) => {
  const { currentPage, setCurrentPage, pageCount = 1, className = "" } = props;

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<ChevronRight size={18} className="text-primary" />}
      previousLabel={<ChevronLeft size={18} className="text-primary" />}
      forcePage={currentPage - 1}
      onPageChange={(page) => {
        setCurrentPage(page.selected + 1);
      }}
      pageRangeDisplayed={4}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      activeClassName="text-white bg-primary"
      activeLinkClassName="text-white bg-primary inline-flex w-[30px] h-[30px] rounded-full justify-center items-center font-semibold"
      pageClassName="inline-flex mx-1 justify-center items-center w-[30px] h-[30px] rounded-full bg-gray-100 text-gray-800"
      breakClassName="inline-block"
      pageLinkClassName="page-link select-none"
      breakLinkClassName="page-link"
      nextClassName="inline-flex w-[30px] h-[30px] rounded-full justify-center items-center select-none bg-gray-100 text-gray-800 hover:text-primary"
      previousClassName="inline-flex w-[30px] h-[30px] rounded-full justify-center items-center select-none bg-gray-100 text-gray-800 hover:text-primary"
      containerClassName={cn("inline", className)}
    />
  );
};

export default Pagination;
