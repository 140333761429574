import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ArrowLeft } from "react-feather";
import InquiryHotelList from "../../features/inquiry/InquiryHotelList";
import getAHotelById from "../../services/hotel/getAHotelById";

const InquiryByHotel = () => {
  const { hotelId } = useParams();
  const [hotelInfo, setHotelInfo] = useState("");

  const getAHotelInfo = async () => {
    try {
      const response = await getAHotelById({ hotelId });

      setHotelInfo(response?.data?.hotel?.name);
    } catch (e) {
      toast.error(e.response.body.error);
    }
  };
  useEffect(() => {
    getAHotelInfo();
  }, []);

  return (
    <>
      <div className="app-layout py-[16px] sticky top-0 border-b z-50 border-neutral-300 bg-white">
        <div className="flex items-center gap-3">
          <Link to={-1}>
            <ArrowLeft className="text-neutral-600" />
          </Link>
          <h3 className="title">Inquiry - {hotelInfo && hotelInfo}</h3>
        </div>
      </div>

      <InquiryHotelList className="app-layout mt-[32px]" />
    </>
  );
};

export default InquiryByHotel;
