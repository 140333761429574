import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import cn from "classnames";
import useWindowSize from "../../hooks/useWindowSize";
import SearchField from "../ui/SearchField";

const BodyHeader = ({
  title = "",
  displaySearchField = true,
  displayCreateButton = true,
  setSearchValue,
  handleCreateButton,
  buttonText = "Create item",
  searchPlaceholder = "Search items",
  className = "",
}) => {
  const { width } = useWindowSize();

  const [searchText, setSearchText] = useState("");
  const [searchedValue] = useDebounce(searchText.trim(), 800);

  useEffect(() => {
    if (displaySearchField) {
      setSearchValue(searchedValue);
    }
  }, [searchedValue]);

  if (width < 1024) {
    return (
      <div className={cn(className, "py-[16px] bg-white")}>
        <div className="flex items-center justify-between">
          <h3 className="title">{title}</h3>
          {displayCreateButton ? (
            <button
              className="primary-btn rounded-[5px] whitespace-nowrap text-white bg-primary"
              onClick={handleCreateButton}
            >
              {buttonText}
            </button>
          ) : null}
        </div>

        {displaySearchField ? (
          <SearchField
            placeholder={searchPlaceholder}
            value={searchText}
            inputClassName="input"
            className="mt-[32px]"
            onChange={(e) => setSearchText(e.target.value)}
          />
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={cn(
        className,
        "flex items-center justify-between py-[16px] bg-white"
      )}
    >
      <h3 className="title">{title}</h3>

      <div className="w-full flex items-center justify-end gap-8">
        {displaySearchField ? (
          <SearchField
            placeholder={searchPlaceholder}
            value={searchText}
            inputClassName="input"
            className="max-w-[300px]"
            onChange={(e) => setSearchText(e.target.value)}
          />
        ) : null}

        {displayCreateButton ? (
          <button
            className="primary-btn rounded-[5px] whitespace-nowrap text-white bg-primary"
            onClick={handleCreateButton}
          >
            {buttonText}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default BodyHeader;
