import http from "../../utils/http";

const getLocationsApi = async ({
  page = 1,
  limit = 25,
  search = undefined,
}) => {
  const response = await http.get("/v1/location", {
    params: { page, limit, search },
  });
  return response.data;
};

export default getLocationsApi;
