import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Modal from "../../components/modal";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import updateRoomApi from "../../services/room/updateRoomApi";

const ROOM_TYPES = [
  {
    label: "Single",
    value: "single",
  },
  {
    label: "Double",
    value: "double",
  },
  {
    label: "Hall",
    value: "hall",
  },
];

const verifyRoomData = (type, description) => {
  if (!type.trim().length) {
    return { isVerified: false, message: "Room type is required." };
  }

  if (!description.trim().length) {
    return { isVerified: false, message: "Description is required." };
  }

  return { isVerified: true, message: "Data is verified." };
};

const UpdateRoomModal = ({
  id,
  isOpen,
  setIsOpen,
  setMutation,
  defaultData,
}) => {
  const [type, setType] = useState(null);
  const [description, setDescription] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setErrorMsg("");
  }, [type, description]);

  useEffect(() => {
    if (isOpen) {
      setType(defaultData?.type || "");
      setDescription(defaultData?.description || "");
    } else {
      setType("");
      setDescription("");
    }
  }, [isOpen, defaultData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isVerified, message } = verifyRoomData(type, description);

    if (!isVerified) {
      setErrorMsg(message);
      return;
    }

    setIsProcessing(true);

    try {
      await updateRoomApi(id, { type, description });

      setMutation();
      toast.success("Room successfully updated");
      setIsOpen(false);
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }

    setIsProcessing(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="w-full max-w-[644px]"
    >
      <h1 className="text-[24px] font-medium">Edit Room</h1>

      <form className="mt-[24px]" onSubmit={handleSubmit}>
        <ErrorMessage errorMsg={errorMsg} className="mb-[16px]" />

        <div>
          <label htmlFor="package-name" className="label">
            Room Type
          </label>
          <select
            className="input mt-[8ppx] bg-transparent"
            value={type || 0}
            onChange={(e) => setType(e.target.value)}
          >
            <option value={0} hidden>
              Select a type
            </option>
            {ROOM_TYPES.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-[16px]">
          <label htmlFor="description" className="label">
            Description
          </label>
          <textarea
            className="input mt-[8px]"
            name=""
            id=""
            cols="20"
            rows="3"
            placeholder="Package description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="mt-[24px] flex justify-end gap-5">
          <button
            className="cancel-btn mt-5 text-neutral-800 bg-neutral-200 hover:bg-neutral-300"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>

          <button
            className="primary-btn mt-5"
            type="submit"
            disabled={isProcessing || errorMsg}
          >
            Update Rom{isProcessing && "..."}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateRoomModal;
