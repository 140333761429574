import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ArrowLeft } from "react-feather";
import EditHotelForm from "../../features/hotel/editHotelForm";
import getHotelApi from "../../services/hotel/getHotelApi";
import getRoomsApi from "../../services/room/getRoomsApi";

const EditHotel = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [roomDropdownOptions, setRoomDropdownOptions] = useState([]);
  const [hotelData, setHotelData] = useState(null);
  const [hotelMutation, setHotelMutation] = useState(false);

  const fetchHotel = async () => {
    try {
      const hotelResData = await getHotelApi(id);

      setHotelData(hotelResData.data.hotel);
      setHotelMutation(false);
    } catch (e) {
      toast.error(e.response.data.error || "Hotel not found");
      navigate(-1);
    }
  };

  const fetchRooms = async () => {
    try {
      const roomResData = await getRoomsApi({ limit: 100 });
      setRoomDropdownOptions(roomResData.data.results);
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  useEffect(() => {
    if (id) {
      fetchHotel();
      fetchRooms();
    }
  }, [id]);

  useEffect(() => {
    if (hotelMutation) {
      fetchHotel();
    }
  }, [hotelMutation]);

  return (
    <>
      <div className="app-layout py-[16px] sticky top-0 border-b z-10 border-neutral-300 bg-white">
        <div className="flex items-center gap-3">
          <Link to={-1}>
            <ArrowLeft className="text-neutral-600" />
          </Link>
          <h3 className="title">Edit Hotel</h3>
        </div>
      </div>

      <EditHotelForm
        hotelId={id}
        hotelData={hotelData}
        roomDropdownOptions={roomDropdownOptions}
        setMutation={setHotelMutation}
        className="app-layout mt-[32px]"
      />
    </>
  );
};

export default EditHotel;
