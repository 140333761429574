import cn from "classnames";
import { Search } from "react-feather";

const SearchField = ({ className = "", inputClassName = "", ...others }) => {
  return (
    <div className={cn("w-full relative", className)}>
      <input {...others} className={cn("pl-[40px]", inputClassName)} />
      <Search
        size={18}
        className="absolute top-[12px] left-[12px] text-neutral-700"
      />
    </div>
  );
};

export default SearchField;
