import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Menu, Transition } from "@headlessui/react";
import cn from "classnames";
import { User } from "react-feather";
import { resetLogin } from "../../redux/slices/userSlice";

const Header = ({ className = "" }) => {
  const dispatch = useDispatch();

  return (
    <header className={cn(className)}>
      <div className="w-full flex items-center justify-end">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="p-[8px] rounded-full bg-neutral-300">
              <User size={24} className="text-neutral-800" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute w-[200px] right-0 mt-2 py-[8px] rounded-[4px] text-[16px] font-normal overflow-hidden [&>*]:block [&>*]:px-3 [&>*]:py-2  text-neutral-900 bg-white shadow-lg">
              <Link to="/profile" className="hover:bg-neutral-200">
                Profile
              </Link>
              <Link to="/profile" className="hover:bg-neutral-200">
                Help
              </Link>
              <Link
                to="/login"
                className="hover:bg-neutral-200"
                onClick={() => dispatch(resetLogin())}
              >
                Logout
              </Link>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
  );
};

export default Header;
