import { useState } from "react";
import cn from "classnames";
import { ChevronsLeft, ChevronsRight } from "react-feather";
import navigation from "../../navigation/navigation";
import useWindowSize from "../../hooks/useWindowSize";
import NavItem from "./NavItem";

const Sidebar = ({ className = "" }) => {
  const [displayFullSidebar, setDisplayFullSidebar] = useState(true);
  const { width } = useWindowSize();

  return (
    <div className={cn("flex flex-col pb-[8px] gap-y-3", className)}>
      <div className="h-[60px] px-[16px] flex items-center justify-between border-b">
        <p
          className={cn(
            "grow transition-all duration-300 overflow-hidden whitespace-nowrap",
            {
              "w-[0px]": !displayFullSidebar || width < 768,
              "w-[160px]": displayFullSidebar && width >= 768,
            }
          )}
        >
          Home Stay
        </p>

        {displayFullSidebar ? (
          <ChevronsLeft
            size={18}
            className="cursor-pointer text-gray-700 hover:text-primary-dark"
            onClick={() => setDisplayFullSidebar(false)}
          />
        ) : (
          <ChevronsRight
            size={18}
            className="cursor-pointer text-gray-700 hover:text-primary-dark"
            onClick={() => setDisplayFullSidebar(true)}
          />
        )}
      </div>

      <div className="px-[8px] flex flex-col gap-y-[8px]">
        {navigation.map((item) => (
          <NavItem
            key={item.id}
            route={item}
            windowWidth={width}
            displayFullSidebar={displayFullSidebar}
          />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
