import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import AddLocationForm from "../../features/location/AddLocationForm";

const AddLocation = () => {
  return (
    <>
      <div className="app-layout py-[16px] sticky top-0 border-b border-neutral-300 bg-white">
        <div className="flex items-center gap-3">
          <Link to={-1}>
            <ArrowLeft className="text-neutral-600" />
          </Link>
          <h3 className="title">Add Location</h3>
        </div>
      </div>

      <AddLocationForm className="app-layout mt-[32px]" />
    </>
  );
};

export default AddLocation;
