import http from "../../utils/http";

const getHotelsApi = async ({ page = 1, limit = 25, search = undefined }) => {
  const response = await http.get("/v1/hotel", {
    params: { page, limit, search },
  });
  return response.data;
};

export default getHotelsApi;
