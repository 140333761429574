import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import cn from "classnames";

const NavItem = ({ route, windowWidth, displayFullSidebar }) => {
  const { pathname } = useLocation();
  const [isHover, setIsHover] = useState(false);

  const isActiveNav =
    (route.additionPath || []).includes(pathname) || pathname === route.path;

  return (
    <Link
      to={route.path}
      key={route.id}
      className={cn("px-[8px] py-[8px] flex items-center rounded-md", {
        "bg-primary-light": isHover || isActiveNav,
      })}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <route.icon
        size={16}
        className={cn("flex-none", {
          "text-primary": isHover || isActiveNav,
          "text-neutral-600": !isHover && !isActiveNav,
        })}
      />

      <div
        className={cn("grow transition-all duration-300 overflow-hidden", {
          "w-[0px]": !displayFullSidebar || windowWidth < 768,
          "w-[160px]": displayFullSidebar && windowWidth >= 768,
        })}
      >
        <p
          className={cn("pl-[8px] text-[15px] font-normal", {
            "text-primary": isHover || isActiveNav,
            "text-neutral-800": !isHover && !isActiveNav,
          })}
        >
          {route.name}
        </p>
      </div>
    </Link>
  );
};

export default NavItem;
