import { Link } from "react-router-dom";
import cn from "classnames";
import Pagination from "../../components/pagination";

const InquiryTableList = ({ paginationDetails, className = "" }) => {
  const { data, setCurrentPage } = paginationDetails;
  
  return (
    <>
      <table className={cn("w-full table-auto", className)}>
        <thead>
          <tr>
            <th>HOTEL NAME</th>
            <th>HOTEL CONTACT</th>
            <th>TOTAL INQUIRY</th>
            <th></th>
          </tr>
        </thead>

        <tbody>

          <tr className="hover:bg-transparent">
            <td colSpan={4} className="p-0 m-0 pt-2"></td>
          </tr>

        {data?.data?.map((hotelData)=>
          <tr key={hotelData?._id}>
          <td className="font-[550] flex items-center gap-3 py-3">
            <div className="block">
              <div className="capitalize">{hotelData?.name}</div>

              <div className="font-normal text-sm">{hotelData?.location?.name}</div>
            </div>
          </td>
          <td>{hotelData?.phoneNumber}</td>
          <td className="flex gap-1">
            <span className="bg-[#FCECE8] px-3 py-1 text-semantic-r rounded-2xl">
              {hotelData?.totalInquiry} Inquiry
            </span>
            <span className="w-[8px] h-[8px] bg-semantic-r rounded-full" hidden={hotelData?.markAsRead}></span>
          </td>
          <td>
            <Link to={`/inquiry/hotel/${hotelData?._id}`} className="underline">
              View Inquiry
            </Link>
          </td>
        </tr>
        )}
       
        </tbody>
      </table>

      <Pagination
        currentPage={data.page}
        pageCount={data.totalPage}
        setCurrentPage={setCurrentPage}
        className="app-layout mt-[32px] flex justify-end"
      />
    </>
  );
};

export default InquiryTableList;
