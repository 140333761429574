import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import EditLocationForm from "../../features/location/EditLocationForm";

const EditLocation = () => {
  return (
    <>
      <div className="app-layout py-[16px] sticky top-0 border-b border-neutral-300 bg-white">
        <div className="flex items-center gap-3">
          <Link to={-1}>
            <ArrowLeft className="text-neutral-600" />
          </Link>
          <h3 className="title">Edit Location</h3>
        </div>
      </div>

      <EditLocationForm className="app-layout mt-[32px]" />
    </>
  );
};

export default EditLocation;
