import http from "../../utils/http";

const getInquiryOfAHotelApi = async ({ hotelId, page = 1, limit = 2, search = undefined }) => {
  //  /api/v1/hotel/:hotelId/?inquery=true&hotel=true
  const response = await http.get(`/v1/hotel/${hotelId}/admin/inquiry`, {
    params: { page, limit, search, inquerybool:true },
  });
  console.log(response)
  return response.data;
};

export default getInquiryOfAHotelApi;
