import cn from "classnames";
import { Star } from "react-feather";

const RatingStar = ({ rating = 5, className = "" }) => {
  return (
    <div className={cn("flex gap-1", className)}>
      {Array.from(Array(5).keys()).map((item) => {
        if (item <= rating) {
          return <Star key={item} size={18} fill="#ffab00" stroke="#ffab00" />;
        }
        return <Star key={item} size={18} className="text-[#ffab00]" />;
      })}
    </div>
  );
};

export default RatingStar;
