import http from "../../utils/http";

const getInquiryApi = async ({ page = 1, limit = 2, search = undefined }) => {
  //  /api/v1/hotel?inquiry=true
  const response = await http.get("/v1/hotel", {
    params: { page, limit, search, inquiry:true}
  });
  console.log('Primary Response', response)
  return response.data;
};

export default getInquiryApi;
