import { useState, useEffect } from "react";

const initialState = {
  data: [],
  totalPage: 1,
  dataCount: 0,
  page: 1,
  fetch: false,
  limit: 25,
  isFetching: true,
  otherParams: undefined,
};

const usePagination = ({
  fetchMethod,
  page = 1,
  limit = 25,
  otherParams = undefined,
  isInitialAutoFetch = true,
}) => {
  const [data, setData] = useState(initialState);

  async function fetchData() {
    let responseData = null;

    try {
      const response = await fetchMethod({
        page: data.page,
        limit: data.limit,
        ...data.otherParams,
      });

      responseData = response.data;
    } catch (e) {
      setData({ ...data, isFetching: false, fetch: false });
      return;
    }

    // Make the data state to fetch data if the current page has no data
    if (data.page !== 1 && !responseData.results) {
      setData({ ...data, page: data.page - 1, fetch: true });
      return;
    }

    setData({
      ...data,
      data: responseData?.results,
      totalPage: parseInt(
        responseData?.count ? Math.ceil(responseData?.count / data.limit) : 0
      ),
      dataCount: responseData?.count,
      isFetching: false,
      fetch: false,
    });
  }

  useEffect(() => {
    if (!isInitialAutoFetch) return;
    setData({ ...initialState, fetch: true, page, limit, otherParams });
  }, [isInitialAutoFetch]);

  useEffect(() => {
    if (data.fetch) fetchData();
    else {
      if (data.otherParams?.search.trim().length < 2 && data.data.length) {
        setData({ ...data, data: [] });
      }
    }
  }, [data]);

  function setCreateMutation() {
    setData({ ...data, page: 1, fetch: true, isFetching: true });
  }

  function setUpdateMutation() {
    setData({ ...data, fetch: true });
  }

  function setDeleteMutation() {
    setData({ ...data, fetch: true });
  }

  function setCurrentPage(page) {
    if (!page || data.page === page || page < 1 || page > data.totalCount) {
      return;
    }

    setData({ ...data, page, fetch: true, isFetching: true });
  }

  function setLimit(limit = 25) {
    setData({ ...data, page: 1, limit, fetch: true, isFetching: true });
  }

  function setOtherParams(otherParams = undefined) {
    if (!otherParams || !otherParams.keys.length) {
      return;
    }

    setData({
      ...data,
      otherParams: { ...data.otherParams, ...otherParams },
      page: 1,
      fetch: true,
      isFetching: true,
    });
  }

  function setSearchValue(searchValue) {
    setData({
      ...data,
      otherParams: { ...data.otherParams, search: searchValue },
      page: 1,
      fetch: searchValue.trim().length >= 2,
      isFetching: true,
    });
  }

  function reset() {
    setData(initialState);
  }

  function reFetch() {
    setData({ ...data, fetch: true });
  }

  return {
    data,
    setCreateMutation,
    setUpdateMutation,
    setDeleteMutation,
    setCurrentPage,
    setLimit,
    setOtherParams,
    setSearchValue,
    reset,
    reFetch,
  };
};

export default usePagination;
