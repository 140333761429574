import "./App.css";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { store } from "./redux/store";
import Router from "./router/Router";

function App() {
  return (
    <>
      <Provider store={store}>
        <Router />
      </Provider>

      <Toaster
        position="top-right"
        toastOptions={{
          error: {
            duration: 3000,
          },
        }}
      />
    </>
  );
}

export default App;
