import { ChevronLeft } from "react-feather";
import { Link, useNavigate } from "react-router-dom";

const VerifyForgotPasswordOTP = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/forgot-password/create-new-password");
  };

  return (
    <div className="h-[100vh] grid grid-cols-1 lg:grid-cols-2">
      <div className="hidden lg:block bg-gray-200"></div>

      <div className="px-[16px] py-[60px]">
        <div className="max-w-[456px] mx-auto">
          <div className="flex items-center justify-between">
            <Link to="/forgot-password" className="flex items-center gap-1">
              <ChevronLeft size={16} className="text-primary" />
              <p className="text-[14px] font-medium text-neutral-600">Back</p>
            </Link>

            <p className="text-[12px] font-normal tracking-[0.02em] text-neutral-500">
              Remember Password ?{" "}
              <Link to="/login" className="text-primary">
                Login
              </Link>
            </p>
          </div>

          <div className="flex justify-center">
            <div className="mt-[80px] w-full">
              <h1 className="text-[28px] tracking-[0.02em] font-medium text-neutral-900">
                Enter OTP Code
              </h1>
              <p className="mt-2 text-[16px] font-medium text-neutral-700">
                We have send 4 digit OTP Code send to
                <span className="text-neutral-900"> homestay@gmail.com</span>.
                Enter the code in the message to verify your account.
              </p>
            </div>
          </div>

          <form className="mt-[70px]" onSubmit={handleSubmit}>
            <div className="flex justify-center">
              <div className="inline-flex gap-4">
                <input className="input w-[60px] h-[60px] text-[18px] text-center" />
                <input className="input w-[60px] h-[60px] text-[18px] text-center" />
                <input className="input w-[60px] h-[60px] text-[18px] text-center" />
                <input className="input w-[60px] h-[60px] text-[18px] text-center" />
              </div>
            </div>

            <button className="mt-[60px] w-full p-3 primary-btn" type="submit">
              Verify Account
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyForgotPasswordOTP;
