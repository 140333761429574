import Login from "../../pages/auth/Login";
import SignUp from "../../pages/auth/SignUp";
import VerifySignUpOTP from "../../pages/auth/VerifySignUpOTP";
import ForgotPassword from "../../pages/auth/ForgotPassword";
import VerifyForgotPasswordOTP from "../../pages/auth/VerifyForgotPasswordOTP";
import NewPasswordForForFotPassword from "../../pages/auth/NewPasswordForForFotPassword";

const authRoutes = [
  {
    id: "login",
    path: "/login",
    component: Login,
    meta: {
      appLayout: false,
      privateRoute: false,
    },
  },

  {
    id: "signup",
    path: "/sign-up",
    component: SignUp,
    meta: {
      appLayout: false,
      privateRoute: false,
    },
  },

  {
    id: "verify-signup-otp",
    path: "/verify-sign-up-otp",
    component: VerifySignUpOTP,
    meta: {
      appLayout: false,
      privateRoute: false,
    },
  },

  {
    id: "forgot-password",
    path: "/forgot-password",
    component: ForgotPassword,
    meta: {
      appLayout: false,
      privateRoute: false,
    },
  },

  {
    id: "verify-forgot-password",
    path: "/verify-forgot-password",
    component: VerifyForgotPasswordOTP,
    meta: {
      appLayout: false,
      privateRoute: false,
    },
  },

  {
    id: "new-password-for-forgot-password",
    path: "/forgot-password/create-new-password",
    component: NewPasswordForForFotPassword,
    meta: {
      appLayout: false,
      privateRoute: false,
    },
  },
];

export default authRoutes;
