import toast from "react-hot-toast";
import usePagination from "../../hooks/usePagination";
import BodyHeader from "../../components/bodyHeader";
import ReviewCard from "../../features/reviews/ReviewCard";
import Pagination from "../../components/pagination";
import getReviewsApi from "../../services/review/getReviewsApi";
import approveReviewApi from "../../services/review/approveReviewApi";

const Reviews = () => {
  const { data, setCurrentPage, setUpdateMutation } = usePagination({
    fetchMethod: getReviewsApi,
  });
  const approveRating = async (id) => {
    try {
      await approveReviewApi(id, true);

      setUpdateMutation(true);
      toast.success("Review successfully approved");
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  const denyRating = async (id) => {
    try {
      await approveReviewApi(id, false);

      setUpdateMutation(true);
      toast.success("Review successfully denied");
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  return (
    <>
      <BodyHeader
        title="Review"
        displaySearchField={false}
        displayCreateButton={false}
        className="app-layout lg:sticky top-0 border-b"
      />
      {data.data.map((item) => (
        <ReviewCard
          key={item._id}
          data={item}
          className="app-layout mt-[32px] mx-3"
          approveRating={approveRating}
          denyRating={denyRating}
        />
      ))}

      <Pagination
        currentPage={data.page}
        pageCount={data.totalPage}
        setCurrentPage={setCurrentPage}
        className="app-layout mt-[32px] flex justify-end"
      />
    </>
  );
};

export default Reviews;
