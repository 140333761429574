import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Modal from "../../components/modal";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import createPackageApi from "../../services/package/createPackageApi";

function verifyPackageData({ name, price, description }) {
  if (!name.trim()) {
    return { isVerified: false, message: "Package name is required field." };
  }

  if (isNaN(price) && Number(price)) {
    return { isVerified: false, message: "Price is required field." };
  }

  if (!description.trim()) {
    return {
      isVerified: false,
      message: "Description is required field.",
    };
  }

  return { isVerified: true, message: "Data verified." };
}

const CreatePackageModal = ({ isOpen, setIsOpen, setMutation }) => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setErrorMsg("");
  }, [name, price, description]);

  useEffect(() => {
    if (isOpen) return;

    setName("");
    setDescription("");
    setPrice(0);
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isVerified, message } = verifyPackageData({
      name,
      price,
      description,
    });

    if (!isVerified) {
      setErrorMsg(message);
      return;
    }

    setIsProcessing(true);

    try {
      await createPackageApi({
        name,
        price,
        description,
      });

      toast.success("Package successfully created");
      setMutation();
      setIsOpen(false);
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }

    setIsProcessing(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="w-full max-w-[644px]"
    >
      <h1 className="text-[24px] font-medium">Create Package</h1>

      <form className="mt-[24px]" onSubmit={handleSubmit}>
        <ErrorMessage errorMsg={errorMsg} className="mb-[16px]" />

        <div>
          <label htmlFor="package-name" className="label">
            Package Name
          </label>
          <input
            type="text"
            className="input mt-[8px]"
            placeholder="Enter package name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="mt-[16px]">
          <label htmlFor="price-per-person" className="label">
            Price per Person
          </label>
          <input
            type="number"
            className="input mt-[8px]"
            placeholder="Enter price"
            value={price || ""}
            onChange={(e) => {
              if (e.target.value >= 0) {
                setPrice(e.target.value);
              }
            }}
          />
        </div>

        <div className="mt-[16px]">
          <label htmlFor="description" className="label">
            Description
          </label>
          <textarea
            className="input mt-[8px]"
            name=""
            id=""
            cols="20"
            rows="3"
            placeholder="Package description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="mt-[24px] flex justify-end gap-5">
          <button
            className="cancel-btn mt-5 text-neutral-800 bg-neutral-200 hover:bg-neutral-300"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>

          <button
            className="primary-btn mt-5"
            type="submit"
            disabled={isProcessing || errorMsg}
          >
            Create Package{isProcessing && "..."}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreatePackageModal;
