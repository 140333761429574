import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="flex items-center justify-center mt-10">
      <div>
        <h1 className="text-[24px] font-semibold text-gray-900">
          Page not found
        </h1>
        <p className="mt-2 text-[16px] text-gray-700">Invalid URL</p>
        <Link
          to="/"
          className="block mt-4 py-2 border-[1px] text-center rounded-lg text-primary border-primary hover:bg-primary hover:text-white"
        >
          Go to home page
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
