import cn from "classnames";
import Pagination from "../../components/pagination";

const UserTableList = ({ paginationDetails, className = "" }) => {
  const { data, setCurrentPage } = paginationDetails;

  return (
    <>
      <table className={cn("w-full table-auto", className)}>
        <thead>
          <tr>
            <th>USERNAME</th>
            <th>EMAIL</th>
            <th>ADDRESS</th>
            <th>CONTACT NUMBER</th>
          </tr>
        </thead>

        <tbody>
          <tr className="hover:bg-transparent">
            <td colSpan={4} className="p-0 m-0 pt-2"></td>
          </tr>

          {data.data.map((item) => (
            <tr key={item._id}>
              <td className="font-[550] flex items-center gap-2 py-2">
                <div className="w-[40px] aspect-square rounded-full border-2 bg-gray-100"></div>
                <p className="capitalize">
                  {item.firstName} {item.lastName}
                </p>
              </td>
              <td>{item.email}</td>
              <td>{item.address}</td>
              <td>{item.phoneNumber}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        currentPage={data.page}
        pageCount={data.totalPage}
        setCurrentPage={setCurrentPage}
        className="app-layout mt-[32px] flex justify-end"
      />
    </>
  );
};

export default UserTableList;
