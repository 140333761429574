import cn from "classnames";
import imagesPlaceholder from "../../../assets/location/images-placeholder.png";

const UploadImage = ({
  setImage,
  title = "Choose image of the hotel",
  description = "Upload from your device",
  className = "",
}) => {
  return (
    <div className={cn("", className)}>
      <div className="flex justify-center">
        <img src={imagesPlaceholder} alt="placeholder" className="w-[120px]" />
      </div>

      <h3 className="mt-3 text-[20px] font-medium text-neutral-800">{title}</h3>

      <label className="label mt-4 font-medium text-[14px] underline cursor-pointer text-neutral-700">
        {description}
        <input
          type="file"
          accept="image/*"
          className="hidden"
          onChange={(e) => setImage(e.target.files[0])}
        />
      </label>
    </div>
  );
};

export default UploadImage;
