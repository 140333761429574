import { useEffect, useState } from "react";
import PackageTableList from "../../features/package/PackageTableList";
import CreatePackageModal from "../../features/package/CreatePackageModal";
import UpdatePackageModal from "../../features/package/UpdatePackageModal";
import BodyHeader from "../../components/bodyHeader";
import usePagination from "../../hooks/usePagination";
import getPackagesApi from "../../services/package/getPackagesApi";

const Package = () => {
  const packageData = usePagination({ fetchMethod: getPackagesApi });
  const searchPackageData = usePagination({
    fetchMethod: getPackagesApi,
    isInitialAutoFetch: false,
  });

  const [searchText, setSearchText] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const [isUpdateModalOpened, setIsUpdateModalOpened] = useState(false);

  const activeTab = searchText.length >= 2 ? searchPackageData : packageData;

  useEffect(() => {
    searchPackageData.setSearchValue(searchText);
  }, [searchText]);

  const createMutation = () => {
    packageData.setCreateMutation();

    if (searchText.trim().length >= 2) {
      searchPackageData.setCreateMutation();
    }
  };

  const updateMutation = () => {
    packageData.setUpdateMutation();

    if (searchText.trim().length >= 2) {
      searchPackageData.setUpdateMutation();
    }
  };

  const deleteMutation = () => {
    packageData.setDeleteMutation();

    if (searchText.trim().length >= 2) {
      searchPackageData.setDeleteMutation();
    }
  };

  const openUpdatePackageModal = (data) => {
    setSelectedPackage(data);
    setIsUpdateModalOpened(true);
  };

  return (
    <>
      <BodyHeader
        title="Package"
        setSearchValue={setSearchText}
        buttonText="Create Package"
        handleCreateButton={() => setIsCreateModalOpened(true)}
        searchPlaceholder="Search packages"
        className="app-layout lg:sticky top-0"
      />

      <PackageTableList
        paginationDetails={activeTab}
        deleteMutation={deleteMutation}
        openUpdatePackageModal={openUpdatePackageModal}
      />

      <CreatePackageModal
        isOpen={isCreateModalOpened}
        setIsOpen={setIsCreateModalOpened}
        setMutation={createMutation}
      />

      <UpdatePackageModal
        id={selectedPackage?._id}
        isOpen={isUpdateModalOpened}
        setIsOpen={setIsUpdateModalOpened}
        setMutation={updateMutation}
        initialData={selectedPackage}
      />
    </>
  );
};

export default Package;
