import { Fragment } from "react";
import cn from "classnames";
import { Menu, Transition } from "@headlessui/react";
import imagesPlaceholder from "../../../../assets/location/images-placeholder.png";
import { MoreHorizontal } from "react-feather";

const SecondaryHotelImageList = ({
  imageList,
  addImage,
  deleteImage,
  updateImage,
  className = "",
}) => {
  return (
    <div className={cn("grid grid-cols-2 gap-[16px]", className)}>
      {imageList.map((image, i) => (
        <div key={i} className="relative">
          <img
            src={typeof image === "object" ? URL.createObjectURL(image) : image}
            alt="room"
            className="w-full h-full aspect-[1/1] object-cover  bg-neutral-200"
          />

          <div className="absolute top-[10px] right-[10px]">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="p-[4px] rounded-full bg-neutral-200 hover:bg-neutral-400">
                  <MoreHorizontal size={18} className="text-neutral-800" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute w-[180px] right-0 mt-1 py-[8px] rounded-[4px] text-[16px] font-normal overflow-hidden [&>*]:block [&>*]:px-3 [&>*]:py-2  text-neutral-900 bg-white shadow-lg">
                  <label className="cursor-pointer">
                    Change Image
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => updateImage(image, e.target.files[0])}
                    />
                  </label>

                  <button type="button" onClick={() => deleteImage(image)}>
                    Delete
                  </button>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      ))}

      <div className="flex items-center justify-center aspect-[1/1]  bg-neutral-200">
        <div>
          <img
            src={imagesPlaceholder}
            alt="placeholder"
            className="w-[90px] mx-auto"
          />

          <label className="label mt-4 font-medium text-[14px] underline cursor-pointer text-neutral-700">
            Upload from your device
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(e) => addImage(e.target.files[0])}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default SecondaryHotelImageList;
