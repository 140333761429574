import http from "../../utils/http";

const addLocationApi = async (data) => {
  const response = await http.post("/v1/location", data, {
    "Contain-Type": "multipart/form-data",
  });
  return response.data;
};

export default addLocationApi;
