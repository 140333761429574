import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import AddHotelForm from "../../features/hotel/addHotelForm";

const AddHotel = () => {
  return (
    <>
      <div className="app-layout py-[16px] sticky top-0 border-b z-10 border-neutral-300 bg-white">
        <div className="flex items-center gap-3">
          <Link to={-1}>
            <ArrowLeft className="text-neutral-600" />
          </Link>
          <h3 className="title">Add Hotel</h3>
        </div>
      </div>

      <AddHotelForm className="app-layout mt-[32px]" />
    </>
  );
};

export default AddHotel;
