import http from "../../utils/http";

const getPackagesApi = async ({ page = 1, limit = 25, search = undefined }) => {
  const response = await http.get("/v1/package", {
    params: { page, limit, search },
  });
  return response.data;
};

export default getPackagesApi;
