import { useParams } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Search } from "react-feather";
import getInquiryOfAHotelApi from "../../services/inquiry/getInquiryOfAHotelApi";
import http from "../../utils/http";

const InquiryHotelList = () => {
  const { hotelId } = useParams();
  const [storage, setStorage] = useState();
  const [responseData, setResponseData] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserInquiry, setSelectedUserInquiry] = useState();
  const [searchTerm, setSearchTerm] = useState("");


  const isAllInquiryRead =  selectedUserInquiry?.some(
    (inquiry) => inquiry.markAsRead === false
  );


  const getResponseData = async () => {
    try {
      const data = await getInquiryOfAHotelApi({ hotelId });

      setStorage(data?.data);
      setResponseData(data?.data);
      setSelectedUser(data?.data[0]);
      setSelectedUserInquiry(data?.data[0]?.inquiry_doc);

    } catch (e) {
      toast.error(e.response.body.error);
    }
  };

  useEffect(() => {
    getResponseData();
  }, []);


  const selectUser = (email) => {
    const select = responseData?.find((d) => d.email === email);

    setSelectedUser(select);
    setSelectedUserInquiry(select?.inquiry_doc);
  };

  const changeReadStatus = async () => {
    if (!selectedUser) {
      toast.error("No user Selected");
      return;
    }
    
    try{
      await http.patch(
        `/v1/hotel/${selectedUser?.hotelId}/admin/inquiry/mark-as-read`,
        {
          hotelId: selectedUser?.hotelId,
          email: selectedUser?._id,
          markAsRead: isAllInquiryRead 
        }
      );
    }catch(e){
      toast.error(e.response.body.error)
    }

    try{
      const data = await getInquiryOfAHotelApi({ hotelId });
      setResponseData(data?.data);
      setStorage(data?.data);
      getResponseData();
    }catch(e){
      toast.error(e.response.body.error)
    }
  };

  const handleSearch = (e) => {
    e?.preventDefault();
    if (!searchTerm.length) {
      setResponseData(storage);
      setSelectedUser(storage[0]);
      setSelectedUserInquiry(storage[0]?.inquiry_doc);
      return;
    }

    const filter = responseData.filter((data) =>
      data.name.includes(searchTerm)
    );

    setResponseData(filter);
    setSelectedUser(filter[0]);
    setSelectedUserInquiry(filter[0]?.inquiry_doc);
  };

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-10">
        <div className=" lg:col-span-2 mt-8 ml-6 text-neutral-800">
          <div>Inquiry By</div>

          <form
            onSubmit={handleSearch}
            className="flex px-4 py-3 rounded-lg bg-neutral-200"
            style={{ border: "1px solid #DDD" }}
          >
            <Search className="content-center " />
            <input
              className="w-full border-none pl-3 outline-0 bg-neutral-200"
              placeholder="Search by User"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </form>

          <div className="mt-2">
            {responseData?.length ? (
              responseData?.map((data) => (
                <div
                  key={data._id}
                  className={`flex p-4 rounded-lg cursor-pointer border-b-2 border-neutral-200 ${
                    data?.email === selectedUser?._id ? "bg-neutral-200" : ""
                  }`}
                  id={data?.email}
                  onClick={(e) => {
                    selectUser(e.target.id);
                  }}
                >
                  <div id={data?.email}>
                    <div className="font-semibold" id={data?.email}>
                      {data?.name}
                    </div>
                    <div className="mt-2" id={data?.email}>
                      {data?.email}
                    </div>
                  </div>
                  <span
                    className={`w-[8px] h-[8px] bg-semantic-r rounded-full ml-auto ${
                      data.inquiry_doc?.some(
                        (inquiry) => inquiry.markAsRead === false
                      )? "show" : "hidden"
                    }`}
                    id={data.email}
                  ></span>
                </div>
              ))
            ) : (
              <div className="flex flex-col w-[50%] m-auto">
                <img className="mt-6" src="/searchnotfound.png" />
                <div className="m-auto font-bold text-neutral-700">
                  User not Found.{" "}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className=" relative lg:col-span-4  mt-8 border rounded-xl ">
          <div className="flex justify-end p-[16px] bg-neutral-200 ">
            <div></div>
            <div>
              <button
                className="bg-neutral-900 text-neutral-200 px-[16px] py-[8px] rounded-[5px] "
                onClick={() => {
                  changeReadStatus();
                }}
              >
                Mark as {`${isAllInquiryRead?'Read' : "Unread"}`}
              </button>
            </div>
          </div>

          <div className="overflow-auto">
            <div className="py-[24px] pl-[84px] pr-[24px] ">
              {selectedUserInquiry?.map((inquiry, index) => (
                <Fragment key={index}>
                  <div className="p-[32px] bg-neutral-200 border-[1px] border-neutral-300 rounded-[16px]">
                    <h3 className="font-semibold">Inquiry By</h3>
                    <div className="mt-2 font-normal text-neutral-700">
                      <p>{inquiry.name}</p>
                      <p>{inquiry.email}</p>
                      <p>{inquiry?.phoneNumber}</p>
                    </div>

                    <h3 className="mt-2 font-semibold">Inquiry About</h3>
                    <div className="font-normal text-neutral-700">
                      <p>{inquiry.about}</p>
                    </div>

                    <h3 className="mt-2 font-semibold">Inquiry Description</h3>
                    <div className="font-normal text-neutral-700">
                      <p>{inquiry.description}</p>
                    </div>
                  </div>

                  <div className="flex justify-end mt-1 mb-6 text-neutral-600">
                    <span>28, Nov 2022 1:30 PM {}</span>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryHotelList;
