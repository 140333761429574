import http from "../../utils/http";

const getAHotelById = async ({ hotelId }) => {
  const response = await http.get(`/v1/hotel/${hotelId}`);
  console.log('response', response)
  return response?.data;

};

export default getAHotelById;
