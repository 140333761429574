import { Home, MapPin, Map, Package, Star, User, Grid, HelpCircle } from "react-feather";

const navigation = [
  { id: "dashboard", name: "Dashboard", path: "/", icon: Grid },

  {
    id: "location",
    name: "Location",
    path: "/location",
    icon: MapPin,
    additionPath: ["/location/add", "/location/edit"],
  },

  {
    id: "hotel",
    name: "Hotel",
    path: "/hotel",
    icon: Home,
    additionPath: ["/hotel/add"],
  },

  { id: "inquiry", name: "Inquiry", path: "/inquiry", icon: HelpCircle, additionPath: ["/inquiry/hotel"] },
  { id: "room", name: "Room", path: "/room", icon: Map },
  { id: "package", name: "Package", path: "/package", icon: Package },
  { id: "review", name: "Review", path: "/review", icon: Star },
  { id: "user", name: "User", path: "/user", icon: User },
];

export default navigation;
