import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Modal from "../../../../components/modal";
import getLocationsApi from "../../../../services/location/getLocationsApi";

const AddLocationModal = ({
  isOpen,
  setIsOpen,
  location,
  setLocation,
  coordinates,
  setCoordinates,
}) => {
  const [locations, setLocations] = useState([]);

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [place, setPlace] = useState("");

  const fetchLocation = async () => {
    try {
      const locationResData = await getLocationsApi({});
      setLocations(locationResData.data.results);
    } catch (e) {
      toast.error(e.response || "Something went wrong, try again later");
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setLatitude(coordinates.latitude);
      setLongitude(coordinates.longitude);
      setPlace(location);
    } else {
      setLongitude("");
      setLongitude("");
      setPlace("");
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setCoordinates({ latitude, longitude });
    setLocation(place);
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="w-full max-w-[644px]"
    >
      <h1 className="text-[24px] font-medium">Add Location</h1>

      <form className="mt-[24px]" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="" className="label">
            Place
          </label>
          <select
            className="input mt-[8px] bg-transparent cursor-pointer"
            value={place || 0}
            onChange={(e) => setPlace(e.target.value)}
          >
            <option value="0" hidden>
              Select a Place
            </option>

            {locations.map((item) => (
              <option key={item._id} value={item._id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-[16px]">
          <label htmlFor="latitude" className="label">
            Latitude
          </label>
          <input
            type="text"
            className="input"
            value={latitude}
            onChange={(e) => {
              const value = e.target.value;
              if (!isNaN(value) && Number(value) >= 0) {
                setLatitude(e.target.value);
              }
            }}
          />
        </div>

        <div className="mt-[16px]">
          <label htmlFor="longitude" className="label">
            Longitude
          </label>
          <input
            type="text"
            className="input"
            value={longitude}
            onChange={(e) => {
              const value = e.target.value;
              if (!isNaN(value) && Number(value) >= 0) {
                setLongitude(e.target.value);
              }
            }}
          />
        </div>

        <div className="mt-[24px] flex justify-end gap-5">
          <button
            className="cancel-btn mt-5 text-neutral-800 bg-neutral-200 hover:bg-neutral-300"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>

          <button className="primary-btn mt-5" type="submit">
            Add Location
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddLocationModal;
