import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cn from "classnames";
import toast from "react-hot-toast";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import signUpApi from "../../services/auth/signUpApi";

function verifyRegisterData({ firstName, lastName, email, password }) {
  if (!firstName.trim()) {
    return { isVerified: false, message: "First name is required." };
  }

  if (!lastName.trim()) {
    return { isVerified: false, message: "Last name is required." };
  }

  if (!email.trim()) {
    return { isVerified: false, message: "Email is required." };
  }

  if (!password) {
    return { isVerified: false, message: "Password is required." };
  }

  return { isVerified: true, message: "Data is verified." };
}

const SignUpForm = ({ className = "" }) => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setErrorMsg("");
  }, [firstName, lastName, email, password, phoneNumber, address]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isVerified, message } = verifyRegisterData({
      firstName,
      lastName,
      email,
      password,
    });

    if (!isVerified) {
      setErrorMsg(message);
      return;
    }

    setIsProcessing(true);

    try {
      const signUpResData = await signUpApi({
        email,
        firstName,
        lastName,
        password,
        phoneNumber: phoneNumber || undefined,
        address: address || undefined,
      });

      toast.success(signUpResData.message);
      navigate("/verify-sign-up-otp", { state: { email } });
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }

    setIsProcessing(false);
  };

  return (
    <form className={cn(className)} onSubmit={handleSubmit}>
      <ErrorMessage errorMsg={errorMsg} className="mb-[16px]" />
      <div className="flex gap-4">
        <div className="w-[50%]">
          <label htmlFor="first-name" className="label">
            First name
          </label>
          <input
            type="text"
            placeholder="Your fist name"
            className="input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="w-[50%]">
          <label htmlFor="first-last" className="label">
            Last name
          </label>
          <input
            type="text"
            placeholder="Your last name"
            className="input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-[16px]">
        <label htmlFor="email" className="label">
          Email
        </label>
        <input
          type="email"
          placeholder="Your email"
          className="input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="mt-[16px]">
        <label htmlFor="password" className="label">
          Password
        </label>
        <input
          type="password"
          placeholder="Your password"
          className="input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="mt-[16px]">
        <label htmlFor="phone-number" className="label">
          Phone number
        </label>
        <input
          type="text"
          placeholder="Your phone number"
          className="input"
          value={phoneNumber}
          onChange={(e) => {
            const value = e.target.value;
            if (!isNaN(value)) setPhoneNumber(e.target.value);
          }}
        />
      </div>

      <div className="mt-[16px]">
        <label htmlFor="address" className="label">
          Address
        </label>
        <input
          type="text"
          placeholder="Your address"
          className="input"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>

      <p className="mt-[16px] text-[12px] font-medium tracking-[0.02em] text-neutral-600">
        By signing in, you're agree to our{" "}
        <Link to="/terms&condition" className="text-primary-dark underline">
          Terms & Condition
        </Link>{" "}
        <Link to="/privacy&policy" className="text-primary-dark underline">
          and Privacy Policy.*
        </Link>
      </p>

      <button
        className="mt-[60px] w-full p-3 primary-btn"
        type="submit"
        disabled={isProcessing || errorMsg}
      >
        Create Account{isProcessing && "..."}
      </button>
    </form>
  );
};

export default SignUpForm;
