import http from "../../utils/http";

const addHotelApi = async (data) => {
  const response = await http.post("/v1/hotel", data, {
    "Contain-Type": "multipart/form-data",
  });
  return response.data;
};

export default addHotelApi;
