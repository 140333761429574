import { useEffect, useState } from "react";
import BodyHeader from "../../components/bodyHeader";
import InquiryTableList from "../../features/inquiry/InquiryTableList";
import usePagination from "../../hooks/usePagination";
import getInquiryApi from "../../services/inquiry/getInquiryApi";

const Inquiry = () => {

	const [searchText, setSearchText] = useState("");

	// make API service to call Inquiry endpoint
	const inquiryData = usePagination({ fetchMethod: getInquiryApi });
	const searchInquiryData = usePagination({
			fetchMethod: getInquiryApi,
			isInitialAutoFetch: false,
	});

  const activeTab = searchText.length >= 2 ? searchInquiryData : inquiryData;

  useEffect(() => {
    searchInquiryData.setSearchValue(searchText);
  }, [searchText]);

  const deleteMutation = () => {
    inquiryData.setDeleteMutation();

    if (searchText.trim().length >= 2) {
      searchInquiryData.setDeleteMutation();
    }
  };


  return (

    <>
      <BodyHeader
        title="Inquiry"
        setSearchValue={setSearchText}
        displayCreateButton={false}
        searchPlaceholder="Search Hotel"
        className="app-layout lg:sticky top-0"
      />

      <InquiryTableList
        paginationDetails={activeTab}
        deleteMutation={deleteMutation}
      />
    </>
  );
};

export default Inquiry;
