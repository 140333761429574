export function verifyLocationData({
  image,
  name,
  longitude,
  latitude,
  description,
}) {
  if (!image) {
    return { isVerified: false, message: "Image is required." };
  }

  if (!name.trim()) {
    return { isVerified: false, message: "Location name is required." };
  }

  if (!latitude) {
    return { isVerified: false, message: "Latitude is required." };
  }

  if (!longitude) {
    return { isVerified: false, message: "Longitude is required." };
  }

  if (!description.trim()) {
    return { isVerified: false, message: "Description is required." };
  }

  return { isVerified: true, message: "Data is verified." };
}

export function verifyLoginData(email, password) {
  if (!email.trim()) {
    return { isVerified: false, message: "Email is required field." };
  }

  if (!password) {
    return { isVerified: false, message: "Password is required field." };
  }

  return { isVerified: true, message: "Data verified." };
}

export const verifyAddRoomData = ({ roomType, count, price, description }) => {
  if (!roomType) {
    return { isVerified: false, message: "Room type is required." };
  }

  if (!count) {
    return { isVerified: false, message: "Number of room is required." };
  }

  if (!price) {
    return { isVerified: false, message: "Price is required." };
  }

  if (!description.trim()) {
    return { isVerified: false, message: "Description is required." };
  }

  return { isVerified: true, message: "Data verified." };
};

export const verifyAddHotelData = ({
  name,
  location,
  coordinates,
  phoneNumber,
  selectedPackage,
  time,
  rooms,
  description,
  coverImage,
}) => {
  if (!name.trim()) {
    return { isVerified: false, message: "Hotel name is required" };
  }

  if (!location) {
    return { isVerified: false, message: "Location is required" };
  }

  if (!coordinates.latitude) {
    return { isVerified: false, message: "Coordinates is required" };
  }

  if (!coordinates.longitude) {
    return { isVerified: false, message: "Coordinates is required" };
  }

  if (!phoneNumber.trim()) {
    return { isVerified: false, message: "Phone number is required" };
  }

  if (!selectedPackage) {
    return { isVerified: false, message: "Selected package is required" };
  }

  if (!time.checkIn) {
    return { isVerified: false, message: "Select the check-in time" };
  }

  if (!time.checkOut) {
    return { isVerified: false, message: "Select the check-out time" };
  }

  if (time.checkIn >= time.checkOut) {
    return {
      isVerified: false,
      message: "Check-in time can't be greater than check-out time",
    };
  }

  if (!rooms.length) {
    return { isVerified: false, message: "Add at least one room" };
  }

  if (!description.trim()) {
    return { isVerified: false, message: "Description is required" };
  }

  if (!coverImage) {
    return { isVerified: false, message: "Cover image is required" };
  }

  return { isVerified: true, message: "Data verified" };
};
