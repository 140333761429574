import cn from "classnames";
import RatingStar from "../../components/ratingStar";

const ReviewCard = ({ className = "", data, approveRating, denyRating }) => {
  return (
    <div className={cn("p-3 border-2 rounded-xl bg-neutral-100", className)}>
      <div className="md:flex justify-between space-x-5">
        <div className="max-w-[500px] flex-none">
          <div className="flex items-center">
            <div
              // src={data.hotel.coverImage}
              alt="coverImg"
              className="w-[40px] rounded-full aspect-square object-cover bg-neutral-300"
            />
            <h5 className="ml-2 text-[14px] font-medium capitalize text-neutral-900">
              {data?.user?.firstName} {data?.user?.lastName}
            </h5>
          </div>

          <div className="flex items-center mt-4">
            <img
              src={data.hotel?.coverImage}
              alt="coverImg"
              className="w-[40px] rounded-full aspect-square object-cover bg-neutral-300"
            />

            <div className="ml-2">
              <h5 className="text-[14px] font-medium text-neutral-900">
                {data.hotel?.name}
              </h5>
              <p className="text-[14px] font-normal text-neutral-700">
                {data.hotel?.phoneNumber}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-5 md:mt-0 grow">
          <h3 className="font-semibold text-[14px] text-neutral-900">
            Review by Customer
          </h3>
          <RatingStar rating={data?.rating} className="my-2" />

          <p className="text-[12px] font-normal tracking-[0.02em]">
            {data?.review}
          </p>
        </div>
      </div>

      <div className="flex justify-end gap-5 mt-2">
        <button className="cancel-btn" onClick={() => denyRating(data._id)}>
          Deny
        </button>
        <button className="primary-btn" onClick={() => approveRating(data._id)}>
          Approve
        </button>
      </div>
    </div>
  );
};

export default ReviewCard;
