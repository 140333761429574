import { useNavigate } from "react-router-dom";
import cn from "classnames";

const CreateNewPasswordForm = ({ className = "" }) => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <form className={cn(className)} onSubmit={handleSubmit}>
      <div>
        <label htmlFor="password" className="label">
          Password
        </label>
        <input
          type="text"
          placeholder="Enter a new password"
          className="input"
        />
      </div>

      <div className="mt-[16px]">
        <label htmlFor="confirm-password" className="label">
          Password
        </label>
        <input
          type="password"
          placeholder="Confirm your new password"
          className="input"
        />
      </div>

      <ul className="list-disc mt-[16px] ml-[25px] text-[14px] font-normal text-neutral-700">
        <li>Password must contain atleast 8 letter.</li>
        <li>Must contain 1 Special Letter.</li>
        <li>Must contain 1 Number.</li>
      </ul>

      <button className="mt-[60px] w-full p-3 primary-btn" type="submit">
        Reset Password
      </button>
    </form>
  );
};

export default CreateNewPasswordForm;
