import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";

const AppLayout = ({ children }) => {
  return (
    <div className="flex gap-[6px] md:gap-[16px] bg-neutral-200">
      <Sidebar className="flex-none bg-white" />

      <div className="grow">
        <Header className="sticky top-0 z-20 h-[60px] flex items-center shadow-sm px-[16px] bg-white" />
        <div className="box-border h-[calc(100vh-74px)] md:h-[calc(100vh-92px)] my-[6px] md:my-[16px] mr-[6px] md:mr-[16px] pb-[16px] rounded-md overflow-auto bg-white">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
