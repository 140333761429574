import http from "../../utils/http";

const updateHotelCoverImgApi = async (hotelId, data) => {
  const response = await http.patch(`/v1/hotel/${hotelId}/cover-image`, data, {
    "Contain-Type": "multipart/form-data",
  });
  return response.data;
};

export default updateHotelCoverImgApi;
