import cn from "classnames";
import toast from "react-hot-toast";
import { Edit, Trash2 } from "react-feather";
import deleteRoomApi from "../../services/room/deleteRoomApi";

const RoomTableList = ({
  paginationDetails,
  deleteMutation,
  openUpdatePackageModal,
  className = "",
}) => {
  const { data } = paginationDetails;

  const handleDelete = async (id) => {
    if (!id) return;

    try {
      await deleteRoomApi(id);

      toast.success("Successfully deleted.");
      deleteMutation();
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  return (
    <>
      <table className={cn("w-full", className)}>
        <thead>
          <tr>
            <th>ROOM TYPE</th>
            <th>DESCRIPTION</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr className="hover:bg-transparent">
            <td colSpan={3} className="p-0 m-0 pt-2"></td>
          </tr>

          {data.data.map((item) => (
            <tr key={item._id}>
              <td className="font-[550] capitalize">{item.type}</td>
              <td>{item.description}</td>

              <td className="flex justify-end gap-4 [&>*]:cursor-pointer">
                <Edit
                  className="text-neutral-600"
                  size={16}
                  onClick={() => openUpdatePackageModal(item)}
                />
                <Trash2
                  className="text-[#FF5630]"
                  size={16}
                  onClick={() => handleDelete(item._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default RoomTableList;
