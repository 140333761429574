import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import allRoutes from "./routes";
import AppLayout from "../layouts/AppLayout";
import PrivateRoute from "./PrivateRoute";
import ScrollToTop from "./ScrollToTop";
import PageNotFound from "../pages/404";

const MergedLayoutRoute = ({ route, children }) => {
  const PrivateRouteWrapper = route?.meta?.privateRoute
    ? PrivateRoute
    : Fragment;
  const AppLayoutWrapper = route?.meta?.appLayout ? AppLayout : Fragment;

  return (
    <PrivateRouteWrapper>
      <AppLayoutWrapper>{children}</AppLayoutWrapper>
    </PrivateRouteWrapper>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {allRoutes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={
              <MergedLayoutRoute route={route}>
                <route.component />
              </MergedLayoutRoute>
            }
          />
        ))}
        <Route
          path="*"
          element={
            <MergedLayoutRoute route={{ meta: { appLayout: true } }}>
              <PageNotFound />
            </MergedLayoutRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
