import { useEffect, useState } from "react";
import UserTableList from "../../features/user/UserTableList";
import BodyHeader from "../../components/bodyHeader";
import usePagination from "../../hooks/usePagination";
import getUsersApi from "../../services/user/getUsersApi";

const User = () => {
  const userData = usePagination({ fetchMethod: getUsersApi });
  const searchUserData = usePagination({
    fetchMethod: getUsersApi,
    isInitialAutoFetch: false,
  });

  const [searchText, setSearchText] = useState("");

  const activeTab = searchText.length >= 2 ? searchUserData : userData;

  useEffect(() => {
    searchUserData.setSearchValue(searchText);
  }, [searchText]);

  const deleteMutation = () => {
    userData.setDeleteMutation();

    if (searchText.trim().length >= 2) {
      searchUserData.setDeleteMutation();
    }
  };

  return (
    <>
      <BodyHeader
        title="User"
        setSearchValue={setSearchText}
        displayCreateButton={false}
        searchPlaceholder="Search users"
        className="app-layout lg:sticky top-0"
      />

      <UserTableList
        paginationDetails={activeTab}
        deleteMutation={deleteMutation}
      />
    </>
  );
};

export default User;
