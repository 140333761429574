import cn from "classnames";
import toast from "react-hot-toast";
import { Edit, Trash2 } from "react-feather";
import Pagination from "../../components/pagination";
import deletePackageApi from "../../services/package/deletePackageApi";

const PackageTableList = ({
  paginationDetails,
  deleteMutation,
  openUpdatePackageModal,
  className = "",
}) => {
  const { data, setCurrentPage } = paginationDetails;

  const handleDelete = async (id) => {
    if (!id) return;

    try {
      await deletePackageApi(id);

      toast.success("Successfully deleted.");
      deleteMutation();
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  return (
    <>
      <table className={cn("w-full", className)}>
        <thead>
          <tr>
            <th>PACKAGE NAME</th>
            <th>PRICE (PER PERSON)</th>
            <th>DESCRIPTION</th>
            <th className="w-[90px]"></th>
          </tr>
        </thead>

        <tbody>
          <tr className="hover:bg-transparent">
            <td colSpan={4} className="p-0 m-0 pt-2"></td>
          </tr>

          {data.data.map((item) => (
            <tr key={item._id}>
              <td className="font-[550]">{item.name}</td>
              <td>Rs {item.price}</td>
              <td>{item.description}</td>
              <td className="flex justify-end gap-4 [&>*]:cursor-pointer">
                <Edit
                  className="text-neutral-600"
                  size={16}
                  onClick={() => openUpdatePackageModal(item)}
                />
                <Trash2
                  className="text-[#FF5630]"
                  size={16}
                  onClick={() => handleDelete(item._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        currentPage={data.page}
        pageCount={data.totalPage}
        setCurrentPage={setCurrentPage}
        className="app-layout mt-[32px] flex justify-end"
      />
    </>
  );
};

export default PackageTableList;
