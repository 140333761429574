import { Link } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import CreateNewPasswordForm from "../../features/auth/CreateNewPasswordForm";

const NewPasswordForForgotPassword = () => {
  return (
    <div className="h-[100vh] grid grid-cols-1 lg:grid-cols-2">
      <div className="hidden lg:block bg-gray-200"></div>

      <div className="px-[16px] py-[60px]">
        <div className="max-w-[456px] mx-auto">
          <div className="flex items-center justify-between">
            <Link to="/forgot-password" className="flex items-center gap-1">
              <ChevronLeft size={16} className="text-primary" />
              <p className="text-[14px] font-medium text-neutral-600">Back</p>
            </Link>

            <p className="text-[12px] font-normal tracking-[0.02em] text-neutral-500">
              Remember Password ?{" "}
              <Link to="/login" className="text-primary">
                Login
              </Link>
            </p>
          </div>

          <div className="flex justify-center">
            <div className="mt-[80px] w-full">
              <h1 className="text-[28px] tracking-[0.02em] font-medium text-neutral-900">
                Create New Password
              </h1>
              <p className="mt-2 text-[16px] font-medium text-neutral-700">
                Set strong password to keep your account secure.
              </p>
            </div>
          </div>

          <CreateNewPasswordForm className="w-full mt-[50px]" />
        </div>
      </div>
    </div>
  );
};

export default NewPasswordForForgotPassword;
