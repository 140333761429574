import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import cn from "classnames";
import toast from "react-hot-toast";
import { MoreHorizontal, Plus } from "react-feather";
import SingleRoom from "../components/SingleRoom";
import UploadImage from "../components/UploadImage";
import EditRoomModal from "./components/modals/EditRoomModal";
import AddLocationModal from "../components/modal/AddLocationModal";
import AddRoomModal from "./components/modals/AddHotelModal";
import SecondaryHotelImageList from "./components/SecondaryHotelImageList";

import getDropdownPackagesApi from "../../../services/package/getDropdownPackages";
import updateHotelApi from "../../../services/hotel/updateHotelApi";
import updateHotelCoverImgApi from "../../../services/hotel/updateHotelCoverImgApi";
import updateHotelSecondaryImgApi from "../../../services/hotel/updateHotelSecondaryImgApi";

const initialServices = {
  wifi: false,
  tv: false,
  heating: false,
  air_condition: false,
  hot_water: false,
};

const EditHotelForm = ({
  hotelId,
  hotelData,
  roomDropdownOptions,
  setMutation,
  className = "",
}) => {
  const [packageDropdownOptions, setPackageDropdownOptions] = useState([]);

  const [coverImage, setCoverImage] = useState();
  const [relatedImages, setRelatedImages] = useState([]);
  const [name, setName] = useState("");
  const [location, setLocation] = useState(null);
  const [coordinates, setCoordinates] = useState({
    latitude: "",
    longitude: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isBreakfastProvided, setIsBreakfastProvided] = useState(true);
  const [parking, setParking] = useState("available_free");
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [time, setTime] = useState({ checkIn: "", checkOut: "" });
  const [description, setDescription] = useState("");
  const [rooms, setRooms] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false);

  const [isAddRoomModalOpened, setIsAddRoomModalOpened] = useState(false);
  const [isAddLocationModalOpened, setIsAddLocationModalOpened] =
    useState(false);

  const [isEditRoomModalOpened, setIsEditRoomModalOpened] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);

  //Fetching all packages for the package dropdown
  const fetchPackages = async () => {
    try {
      const packageResData = await getDropdownPackagesApi();

      setPackageDropdownOptions(packageResData.data);
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  const changeCoverImage = async () => {
    const formData = new FormData();
    formData.append("coverImage", coverImage);

    try {
      await updateHotelCoverImgApi(hotelId, formData);

      setMutation();
      toast.success("Cover image successfully updated");
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  useEffect(() => {
    if (!hotelData) {
      return;
    }

    setName(hotelData.name);
    setLocation(hotelData.location._id);
    setCoordinates({
      latitude: hotelData.coordinates[0],
      longitude: hotelData.coordinates[1],
    });
    setPhoneNumber(hotelData.phoneNumber);
    setSecondaryPhoneNumber(hotelData?.secondaryPhoneNumber || "");
    setEmail(hotelData?.email || "");
    setIsBreakfastProvided(hotelData.breakfast);
    setParking(hotelData.parking);
    setSelectedPackage(
      hotelData.packageHotelRel?.length
        ? hotelData.packageHotelRel[0].package._id
        : null
    );
    setTime({
      checkIn: hotelData.checkInTime,
      checkOut: hotelData.checkOutTime,
    });
    setDescription(hotelData?.description || "");
    setCoverImage(hotelData.coverImage);
    setRelatedImages(hotelData.relatedImages);

    setRooms(
      Array.from(hotelData.room, (item) => {
        const services = initialServices;

        Object.keys(services).forEach((key) => {
          services[key] = item.services.includes(key);
        });

        return { ...item, services };
      })
    );
  }, [hotelData]);

  // Change Cover Image

  useEffect(() => {
    if (!coverImage || typeof coverImage === "string") {
      return;
    }

    changeCoverImage();
  }, [coverImage]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsProcessing(true);

    try {
      await updateHotelApi(hotelId, {
        name,
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        locationId: location,
        phoneNumber,
        secondaryPhoneNumber,
        email,
        breakfast: isBreakfastProvided,
        parking,
        checkInTime: time.checkIn,
        checkOutTime: time.checkOut,
        description,
      });

      toast.success("Successfully updated");
      setMutation(true);
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }

    setIsProcessing(false);
  };

  const addSecondaryImage = async (image) => {
    const formData = new FormData();
    formData.append("relatedImageUrl", JSON.stringify(hotelData.relatedImages));

    formData.append("relatedImages", image);

    try {
      await updateHotelSecondaryImgApi(hotelId, formData);

      setMutation(true);
      toast.success("Secondary image successfully added");
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  const upDateSecondaryImage = async (oldUrl, image) => {
    const formData = new FormData();
    formData.append(
      "relatedImageUrl",
      JSON.stringify(hotelData.relatedImages.filter((url) => oldUrl !== url))
    );

    formData.append("relatedImages", image);

    try {
      await updateHotelSecondaryImgApi(hotelId, formData);

      setMutation(true);
      toast.success("Secondary image successfully updated");
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  const deleteSecondaryImage = async (imageURL) => {
    const formData = new FormData();

    formData.append(
      "relatedImageUrl",
      JSON.stringify(hotelData.relatedImages.filter((url) => imageURL !== url))
    );

    try {
      await updateHotelSecondaryImgApi(hotelId, formData);

      setMutation(true);
      toast.success("Secondary image successfully deleted");
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  const openEditModal = (data) => {
    setSelectedRoom(data);
    setIsEditRoomModalOpened(true);
  };

  console.log({ hotelData });

  return (
    <>
      <div className={cn("", className)}>
        <form className={cn("className", className)} onSubmit={handleSubmit}>
          <div className={cn("grid grid-cols-1 lg:grid-cols-2 gap-10")}>
            <div>
              <div>
                <label htmlFor="" className="label">
                  Hotel Name
                </label>
                <input
                  type="text"
                  className="input mt-[8px]"
                  placeholder="Enter location name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="mt-[16px] flex items-center justify-between">
                <label htmlFor="" className="label">
                  Location ({coordinates.latitude} - {coordinates.longitude})
                </label>
                <Plus
                  className="cursor-pointer text-neutral-600"
                  onClick={() => setIsAddLocationModalOpened(true)}
                />
              </div>

              <div className="mt-[16px]">
                <label htmlFor="" className="label">
                  Contact Number
                </label>
                <input
                  type="text"
                  className="input mt-[8px]"
                  placeholder="Enter primary number"
                  value={phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value.trim();

                    if (!isNaN(value) && value.length <= 10) {
                      setPhoneNumber(value);
                    }
                  }}
                />

                <input
                  type="text"
                  className="input mt-[8px]"
                  placeholder="Enter secondary number"
                  value={secondaryPhoneNumber}
                  onChange={(e) => {
                    const value = e.target.value.trim();

                    if (!isNaN(value) && value.length <= 10) {
                      setSecondaryPhoneNumber(value);
                    }
                  }}
                />
              </div>

              <div className="mt-[16px]">
                <label htmlFor="" className="label">
                  Email
                </label>
                <input
                  type="email"
                  className="input mt-[8px]"
                  placeholder="Enter hotel email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mt-[16px]">
                <label htmlFor="" className="label">
                  Services
                </label>
              </div>

              <div className="mt-[16px]">
                <label htmlFor="" className="label">
                  Breakfast
                </label>

                <div className="flex items-center space-x-5 mt-1">
                  <div className="flex items-center space-x-1">
                    <input
                      type="radio"
                      value="yes"
                      className="cursor-pointer"
                      checked={isBreakfastProvided}
                      onChange={() => setIsBreakfastProvided(true)}
                    />
                    <span className="text-[14px] font-medium text-neutral-900">
                      Yes
                    </span>
                  </div>

                  <div className="flex items-center space-x-1">
                    <input
                      type="radio"
                      value="no"
                      className="cursor-pointer"
                      checked={!isBreakfastProvided}
                      onChange={() => setIsBreakfastProvided(false)}
                    />
                    <span className="text-[14px] font-medium text-neutral-900">
                      No
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-[16px]">
                <label htmlFor="" className="label">
                  Parking
                </label>
                <div className="flex items-center space-x-5 mt-1">
                  <div className="flex items-center space-x-1">
                    <input
                      type="radio"
                      className="cursor-pointer"
                      checked={parking === "available_free"}
                      onChange={() => setParking("available_free")}
                    />
                    <span className="text-[14px] font-medium text-neutral-900">
                      Yes(Free)
                    </span>
                  </div>

                  <div className="flex items-center space-x-1">
                    <input
                      type="radio"
                      className="cursor-pointer"
                      checked={parking === "available_paid"}
                      onChange={() => setParking("available_paid")}
                    />
                    <span className="text-[14px] font-medium text-neutral-900">
                      Yes(Paid)
                    </span>
                  </div>

                  <div className="flex items-center space-x-1">
                    <input
                      type="radio"
                      className="cursor-pointer"
                      checked={parking === "not_available"}
                      onChange={() => setParking("not_available")}
                    />
                    <span className="text-[14px] font-medium text-neutral-900">
                      No
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-[16px] flex items-center justify-between">
                <label htmlFor="" className="label">
                  Room
                </label>
                <Plus
                  className="cursor-pointer text-neutral-600"
                  onClick={() => setIsAddRoomModalOpened(true)}
                />
              </div>

              {rooms.length ? (
                <div className="mt-[16px] flex flex-col gap-[32px]">
                  {rooms.map((room, i) => (
                    <SingleRoom
                      key={i}
                      room={room}
                      openEditModal={openEditModal}
                      className="rounded-md bg-neutral-100"
                    />
                  ))}
                </div>
              ) : null}

              <div className="mt-[16px]">
                <label htmlFor="" className="label">
                  Package
                </label>
                <select
                  className="input mt-[8px] bg-transparent cursor-pointer"
                  value={selectedPackage || 0}
                  onChange={(e) => setSelectedPackage(e.target.value)}
                >
                  <option value="0" hidden>
                    Select a Package
                  </option>

                  {packageDropdownOptions.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mt-[16px]">
                <label htmlFor="" className="label">
                  Time
                </label>
              </div>

              <div className="mt-[16px] flex gap-5">
                <div className="w-full">
                  <label htmlFor="" className="label">
                    Check-in
                  </label>
                  <input
                    type="time"
                    className="input mt-[8px]"
                    value={time.checkIn || ""}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (value < time.checkOut || !time.checkOut) {
                        setTime({ ...time, checkIn: e.target.value });
                      }
                    }}
                  />
                </div>

                <div className="w-full">
                  <label htmlFor="" className="label">
                    Check-out
                  </label>
                  <input
                    type="time"
                    className="input mt-[8px]"
                    value={time.checkOut || ""}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (value > time.checkIn || !time.checkIn) {
                        setTime({ ...time, checkOut: value });
                      }
                    }}
                  />
                </div>
              </div>

              <div className="mt-[16px]">
                <label htmlFor="" className="label">
                  Description
                </label>
                <textarea
                  className="input mt-[8px]"
                  cols="20"
                  rows="3"
                  placeholder="Hotel description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>

            <div>
              <div className="relative max-w-[512px] mt-[8px] p-[32px] text-center border rounded-xl">
                {coverImage || relatedImages.length ? (
                  <>
                    {coverImage ? (
                      <div>
                        <img
                          src={
                            typeof coverImage === "object"
                              ? URL.createObjectURL(coverImage)
                              : coverImage
                          }
                          alt="cover pic"
                          className="w-full aspect-[16/9] object-cover"
                        />
                        <div className="absolute top-[50px] right-[50px]">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button className="p-[4px] rounded-full bg-neutral-200 hover:bg-neutral-400">
                                <MoreHorizontal
                                  size={18}
                                  className="text-neutral-800"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute w-[180px] right-0 mt-1 py-[8px] rounded-[4px] text-[16px] font-normal overflow-hidden [&>*]:block [&>*]:px-3 [&>*]:py-2  text-neutral-900 bg-white shadow-lg">
                                <label className="block cursor-pointer">
                                  Change Image
                                  <input
                                    type="file"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={(e) =>
                                      setCoverImage(e.target.files[0])
                                    }
                                  />
                                </label>
                                <button onClick={() => setCoverImage(null)}>
                                  Delete
                                </button>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                        <p className="absolute  px-2 top-[50px] left-[50px] rounded-md text-neutral-900 bg-neutral-100">
                          Cover Image
                        </p>
                      </div>
                    ) : null}

                    <SecondaryHotelImageList
                      imageList={relatedImages}
                      addImage={addSecondaryImage}
                      deleteImage={deleteSecondaryImage}
                      updateImage={upDateSecondaryImage}
                      className="mt-[32px]"
                    />
                  </>
                ) : (
                  <UploadImage setImage={setCoverImage} />
                )}
              </div>
            </div>
          </div>

          <button
            className="primary-btn mt-[38px]"
            type="submit"
            disabled={isProcessing}
          >
            Edit Hotel{isProcessing && "..."}
          </button>
        </form>
      </div>

      <AddRoomModal
        hotelId={hotelId}
        isOpen={isAddRoomModalOpened}
        setIsOpen={setIsAddRoomModalOpened}
        roomDropdownOptions={roomDropdownOptions}
        setMutation={setMutation}
      />

      <EditRoomModal
        hotelId={hotelId}
        isOpen={isEditRoomModalOpened}
        setIsOpen={setIsEditRoomModalOpened}
        defaultData={selectedRoom}
        roomDropdownOptions={roomDropdownOptions}
        setMutation={setMutation}
      />

      <AddLocationModal
        isOpen={isAddLocationModalOpened}
        setIsOpen={setIsAddLocationModalOpened}
        location={location}
        setLocation={setLocation}
        coordinates={coordinates}
        setCoordinates={setCoordinates}
      />
    </>
  );
};

export default EditHotelForm;
