import http from "../../../utils/http";

const updateHotelRoomApi = async ({ hotelId, roomId, data }) => {
  const response = await http.patch(
    `/v1/hotel/${hotelId}/room/${roomId}`,
    data
  );
  return response.data;
};

export default updateHotelRoomApi;
