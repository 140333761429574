import cn from "classnames";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { Edit, Trash2 } from "react-feather";
import Pagination from "../../components/pagination";
import deleteLocationApi from "../../services/location/deleteLocation";

const LocationTableList = ({
  paginationDetails,
  deleteMutation,
  className = "",
}) => {
  const { data, setCurrentPage } = paginationDetails;

  const deleteLocation = async (id) => {
    try {
      await deleteLocationApi(id);

      toast.success("Successfully deleted.");
      deleteMutation();
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }
  };

  return (
    <>
      <table className={cn("w-full table-auto", className)}>
        <thead>
          <tr>
            <th>LOCATION NAME</th>
            <th>LATITUDE</th>
            <th>LONGITUDE</th>
            <th className="w-[90px]"></th>
          </tr>
        </thead>

        <tbody>
          <tr className="hover:bg-transparent">
            <td colSpan={4} className="p-0 m-0 pt-2"></td>
          </tr>

          {data.data.map((item) => (
            <tr key={item._id}>
              <td className="font-[550]">{item.name}</td>
              <td>{item.coordinates[0]}</td>
              <td>{item.coordinates[1]}</td>

              <td className="flex justify-end gap-4 [&>*]:cursor-pointer">
                <Link to={`/location/edit/${item._id}`}>
                  <Edit className="text-neutral-600" size={16} />
                </Link>
                <Trash2
                  className="text-[#FF5630]"
                  size={16}
                  onClick={() => deleteLocation(item._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        currentPage={data.page}
        pageCount={data.totalPage}
        setCurrentPage={setCurrentPage}
        className="app-layout mt-[32px] flex justify-end"
      />
    </>
  );
};

export default LocationTableList;
