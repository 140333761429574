import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import cn from "classnames";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import { setLogin } from "../../redux/slices/userSlice";
import { verifyLoginData } from "../../utils/validation";
import loginApi from "../../services/auth/loginApi";

const LoginForm = ({ className = "" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setErrorMsg("");
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isVerified, message } = verifyLoginData(email, password);

    if (!isVerified) {
      setErrorMsg(message);
      return;
    }

    setIsProcessing(true);

    try {
      const loginResData = await loginApi({
        email,
        password,
        rememberMe: isRememberMe,
      });

      toast.success("Successfully Login.");

      dispatch(
        setLogin({
          isRememberMe,
          userData: loginResData.data.userData,
          token: loginResData.data.token,
        })
      );
      navigate("/");
    } catch (e) {
      toast.error(
        e?.response?.data?.error || "Timeout, not responding from server"
      );
    }

    setIsProcessing(false);
  };

  return (
    <form className={cn(className)} onSubmit={handleSubmit}>
      <ErrorMessage errorMsg={errorMsg} className="mb-[16px]" />

      <div>
        <label htmlFor="email" className="label">
          Email
        </label>

        <input
          type="email"
          placeholder="Your email"
          className="input"
          value={email}
          onChange={(e) => setEmail(e.target.value.trim())}
        />
      </div>

      <div className="mt-[16px]">
        <label htmlFor="password" className="label">
          Password
        </label>

        <input
          type="password"
          placeholder="Your password"
          className="input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="flex justify-between mt-[16px]">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            className="cursor-pointer"
            checked={isRememberMe}
            onChange={() => setIsRememberMe((state) => !state)}
          />

          <label
            className="text-[14px] cursor-pointer font-normal select-none text-neutral-600"
            onClick={() => setIsRememberMe((state) => !state)}
          >
            Remember me
          </label>
        </div>

        <Link
          to="/forgot-password"
          className="text-[12px] tracking-[0.02em]  font-normal italic underline underline-offset-[3px] text-primary-dark"
        >
          Forgot password?
        </Link>
      </div>

      <button
        className="mt-[60px] w-full p-3 primary-btn"
        type="submit"
        disabled={isProcessing || errorMsg}
      >
        Login{isProcessing && "..."}
      </button>
    </form>
  );
};

export default LoginForm;
